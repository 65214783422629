import React from 'react'

import { getStruct, saveObj, getItem, getList } from '../../store/api.js'

import DropDown     from '../_atoms/DropDown'
import InputText    from '../_atoms/InputText'
import InputImage   from '../_molecules/InputImage'
import TextArea     from '../_atoms/TextArea'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormCategoria extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            filasImpressao: [],
            arrcampos:[],
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async componentDidMount() {
        const struct = await getStruct(this.state.model)
        this.setState({ arrcampos: struct.data })

        const item = await getItem(this.state.model, this.state.id)
        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} } })
        }

        const getfilasImpressao = await getList("FilaImpressao")
        if (getfilasImpressao.data && getfilasImpressao.data != '') {
            const filasImpressao = getfilasImpressao.data.map((element) => ({label:element.identificador, id:element.id}) )
            this.setState({ filasImpressao: filasImpressao })
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
        const filas = this.state.filasImpressao

        if (filas.length > 0 && !this.state.data.items['id_fila_impressao']) {
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'É preciso configurar a categoria a uma fila de impressão.', 'top-right')
        } else {
            try {
                await saveObj(this.state.data, this.state.model)
                Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
                window.history.back()
              } catch (e) {
                Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
              }
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    render() {
        const categ = this.state.arrcampos.filter((element) => element.nome === "id_categoria")
        const icons = this.state.arrcampos.filter((element) => element.nome === "icon")
        const atual = this.state.data.items
        let options = categ.length > 0 ? categ[0].options : null

        if(atual && options){
            options = options.filter((i) => i.id != atual.id)
        }
    
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className="col-md-6">

                        <div className="form-floating mb-15px">
                            <InputImage 
                                nome="id_gb_imagem"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual && atual.imagem ? atual.imagem.imagem : null}
                            />
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="nome"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.nome : ""}
                            />
                            <Label nome="nome" label="Nome da Categoria"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <TextArea
                                nome="descricao"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.descricao : ""}
                            />
                            <Label nome="descricao" label="Descrição da Categoria" textArea={true}/>
                        </div>

                        <div className="form-floating mb-15px">
                            <h4 className="card-title">
                                <span className="btn btn-primary" style={{marginRight:"5px", marginBottom:"10px"}}>
                                    <i className='fa fa-rectangle-list'></i>
                                </span>
                                Setores de impressão
                            </h4>
                            <p style={{fontSize: "15px"}}>
                             Selecione onde todos os produtos desta categoria serão impressos. Para adicionar novos locais de impressão, acesse Configurações > Impressões e cadastre um novo setor.
                            </p>
                            <DropDown 
                                nome="id_fila_impressao"
                                setFomrData={this.setModelData}
                                options={this.state.filasImpressao}
                                dadosIniciais={atual ? atual.id_fila_impressao : false}
                                label="Selecionar uma fila de impressao:"
                            />
                        </div>
                        
                    </div>

                    <div className="col-md-6">
                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="icon"
                                setFomrData={this.setModelData}
                                options={ icons.length > 0 ? icons[0].values : null}
                                dadosIniciais={atual ? atual.icon : false}
                                label="Selecione um icone"
                            />
                        </div>
                        <div className="form-floating mb-15px">
                            <p style={{fontSize: "15px"}}>
                                Gostaria de criar uma categoria como subcategoria de outra? Se sim, selecione aqui:
                            </p>
                            <DropDown 
                                nome="id_categoria"
                                setFomrData={this.setModelData}
                                options={options && options.length > 0 ? options : null}
                                dadosIniciais={atual ? atual.id_categoria : false}
                                label="Selecionar uma categoria mãe:"
                            />
                        </div>

                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}