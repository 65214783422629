import React from 'react'

import { getResultados, saveMetas, getMetas } from './../store/api.js'
import { Link } from 'react-router-dom'
import Helper       from '../config/helper'
import TopPage from '../components/_organisms/TopPage'
import { Modal, ModalBody } from 'reactstrap'
import InputText    from '../components/_atoms/InputText'
import Label        from '../components/_atoms/Label'

export default class Detalhe extends React.Component {
    constructor(props) {
		super(props)
        this.state = {
			metas: null,
            tabs: [],
            filtro: 0,
            modalMedia:false,
            media_mensal: 0,
            totalMes: 0
		}
		
        this.fetchData = this.fetchData.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
        this.hadleModalMediaMensal = this.hadleModalMediaMensal.bind(this)
        this.setMetaMensal = this.setMetaMensal.bind(this)
        this.saveMeta = this.saveMeta.bind(this)
        this.isMeta = this.isMeta.bind(this)
        this.diffConsolidado = this.diffConsolidado.bind(this)
	}

    setMetaMensal(name, valor){
        this.setState({media_mensal: valor})
    }

    async saveMeta(e){
        e.preventDefault()

		try {
            const filtro = this.state.tabs[this.state.filtro].valor.split("/")
            await saveMetas({ "meta": this.state.media_mensal, "data": filtro[1].trim()+"-"+filtro[0].trim()+"-01" })
            await this.fetchData()
            this.setState({modalMedia: !this.state.modalMedia})

            Helper.addNotification('success', 'Sucesso', 'Registro efetuado com sucesso', 'top-right')
        } catch (e) {
            console.log(e)
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    percentDiff(real, projetado){
        return ( real * 100 ) / projetado
    }

    realDiff(real, projetado){
        return real - projetado
    }

    diffConsolidado(){
        const today = new Date()
        const filtro = this.state.tabs[this.state.filtro].valor.split("/")
        const dataFiltro = new Date(filtro[1].trim()+"-"+filtro[0].trim()+"-"+today.getDate())
        let acumuladoMetas = 0
        let acumuladoReal = 0

        if (dataFiltro.getDate() === today.getDate() && dataFiltro.getMonth() === today.getMonth() && dataFiltro.getFullYear() === today.getFullYear()) {
            this.state.metas.forEach((item) => {
                const date = new Date(item.date)

                if (date.getDate() <= dataFiltro.getDate()) {
                    acumuladoMetas += parseFloat(item.meta)
                    if (item.valor) acumuladoReal  +=  parseFloat(item.valor)
                }
            })
        } else {
            this.state.metas.forEach((item) => {
                acumuladoMetas +=  parseFloat(item.meta)
                if (item.valor) acumuladoReal  +=  parseFloat(item.valor)
            })
        }

        return acumuladoReal - acumuladoMetas
    }

    formatDate(valor){
        const date = valor.split("T")[0]
        const arrDt = date.split("-")

        const day  = arrDt[2]
        const month = arrDt[1]
        const year = arrDt[0]

        return day+"/"+month+"/"+year
    }

    async aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter
        const date = this.state.tabs[id].valor.split("/")
        const tabs = new Array()
        const month = parseInt(date[0].trim())
        const year = parseInt(date[1].trim())

		const metas = await getMetas(month+"/"+year)
        const resultados = await getResultados(year+"-"+month+"-01")
        
        resultados.meses_venda.forEach((item, idx) => {
            const date = new Date(item.created_at)

            const month = date.getMonth()
            const year  = date.getFullYear()

            tabs.push({chave: idx, valor: (month+1)+" / "+year})
        })

        metas.forEach((i,idx) => {
            const realizado = resultados.dias.filter( (f) => {
                const dataMeta = new Date(i.date)
                const datarealizado = new Date(f.created_at)
                
                if (dataMeta.getDate() === datarealizado.getDate() && dataMeta.getMonth() === datarealizado.getMonth()) return true
                else return false
            })

            if (realizado[0]) metas[idx].valor = realizado[0].total
        })

        if (metas.length === 0){
            resultados.dias.filter( (f, idx) => {
                resultados.dias[idx].valor = f.total
                resultados.dias[idx].b_mensal = 0
                resultados.dias[idx].date = f.created_at
            })
        }

        this.setState({ metas: metas.length > 0 ? metas : resultados.dias, tabs: tabs, filtro:  this.state.tabs[id].chave, totalMes: resultados.total_mes })
	}

    diaSemana(valor) {
        const date = new Date(valor)
        const arrDias = ["domingo","segunda","terça","quarta","quinta","sexta","sábado"]

        return arrDias[date.getDay()]
    }

    hadleModalMediaMensal(){
        this.setState({modalMedia: !this.state.modalMedia})
    }

    acumulado(idx, arr) {
        let total = 0

        arr.forEach((element, i) => {
            if ( i <= idx && element.valor > 0) {
                total += parseFloat(element.valor)
            }
        })

        return total.toFixed(2)
    }

    metaAcumulado(idx, arr) {
        let total = 0

        arr.forEach((element, i) => {
            if ( i <= idx) {
                total += parseFloat(element.meta)
            }
        })

        return total.toFixed(2)
    }

    mediaMensal(valor){
        const date = new Date()
        const day = date.getDate()

        return parseFloat(valor / parseInt(day)).toFixed(2)
    }

	async fetchData(){
        const date = new Date()
        const month = date.getMonth()
        const year = date.getFullYear()
        const tabs = new Array()
        
        const metas = await getMetas()
        const resultados = await getResultados(year+"-"+(month+1)+"-01")
        
        resultados.meses_venda.forEach((item, idx) => {
            const date = new Date(item.created_at)

            const month = date.getMonth()
            const year  = date.getFullYear()

            tabs.push({chave: idx, valor: (month+1)+" / "+year})
        })

        metas.forEach((i,idx) => {
            const realizado = resultados.dias.filter( (f) => {
                const dataMeta = new Date(i.date)
                const datarealizado = new Date(f.created_at)
                
                if (dataMeta.getDate() === datarealizado.getDate() && dataMeta.getMonth() === datarealizado.getMonth()) return true
                else return false
            })

            if (realizado[0]) metas[idx].valor = realizado[0].total
        })

        if (metas.length === 0){
            resultados.dias.filter( (f, idx) => {
                resultados.dias[idx].valor = f.total
                resultados.dias[idx].b_mensal = 0
                resultados.dias[idx].date = f.created_at
            })
        }

        this.setState({ metas: metas.length > 0 ? metas : resultados.dias, tabs: tabs, filtro: 0, totalMes: resultados.total_mes })
	}

    componentDidMount() {
		this.fetchData()
	}

    isMeta(){
        if (this.state.metas && this.state.tabs.length > 0) {
            const metaMensal = this.state.metas && this.state.metas.length > 0 ? this.state.metas.filter( (a) => a.b_mensal === 1 ) : []
            const filtro = this.state.tabs[this.state.filtro].valor.split("/")
            const today = new Date()
            const dataFiltro = new Date(filtro[1].trim()+"-"+filtro[0].trim()+"-"+today.getDate())

            if (metaMensal.length > 0 && dataFiltro.getDate() === today.getDate() && dataFiltro.getMonth() === today.getMonth() && dataFiltro.getFullYear() === today.getFullYear()) return true
            else if (metaMensal.length > 0) return true
            else return false
        } else {
            return false
        }
    }

    render(){
        const metas = this.state.metas && this.state.metas.length > 0 ? this.state.metas.filter( (a) => a.b_mensal === 0 ) : []
        const resto = this.state.metas && this.state.metas.length > 0 ? this.state.totalMes - this.state.metas[0].meta : 0
        
        return (
			<div>
                <TopPage titulo="Resultados e Metas">
                    <p className="lead">
                        Acompanhe seus resultados e defina metas. Monitore o desempenho diário e alcance os objetivos mensais com facilidade.
                    </p>
                </TopPage>

                
                <div className="row">
                    {this.state.totalMes && (
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats ">
                                <div className="stats-content">
                                    <div className="stats-title">Vendas realizadas</div>
                                    <div className="stats-number" style={{color:"#0043ff"}}>{this.state.totalMes.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </div>

                                    <div className="stats-desc">Média de <b style={{color:"#0043ff"}}>{ parseFloat(this.mediaMensal(this.state.totalMes)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }</b> diários</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.isMeta() ? (
                        <>
                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats ">
                                    <div className="stats-content">
                                        <div className="stats-title">Meta do mês</div>
                                        <div className="stats-number">
                                            <Link to="#" onClick={(e) => { this.hadleModalMediaMensal(e) }} style={{color:"#ff9900",textDecoration:"none"}}>
                                                {this.state.metas && this.state.metas.length > 0 ? parseFloat(this.state.metas[0].meta).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "Defina uma meta"}
                                                <i class="fa fa-pen" style={{marginLeft: "5px", fontSize: "15px"}}></i>
                                            </Link>
                                        </div>

                                        {this.diffConsolidado() > 0 ? ( 
                                            <div className="stats-desc">
                                                <b style={{color:"#0f9e3e"}}>{parseFloat(this.diffConsolidado()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b> acima da media
                                            </div> 
                                        ):( 
                                            <div className="stats-desc">
                                                <b style={{color:"#ff1b30"}}>{parseFloat(this.diffConsolidado()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b> abaixo da media
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats ">
                                    <div className="stats-content">
                                        <div className="stats-title">Realizado em relação a meta</div>
                                        <div className="stats-number" style={resto < 0 ? {color:"#ff1b30"} : {color:"#0f9e3e"}}>
                                            {this.state.metas && this.state.metas.length > 0  && this.state.totalMes > 0 ? parseFloat(resto).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 0}
                                        </div>

                                        <div className="stats-desc"></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats ">
                                <div className="stats-content">
                                    <div className="stats-title">Meta do mês</div>
                                    <div className="stats-number">
                                        <Link to="#" onClick={(e) => { this.hadleModalMediaMensal(e) }} style={{color:"#ff9900",textDecoration:"none"}}>
                                            Defina uma meta
                                            <i class="fa fa-pen" style={{marginLeft: "5px", fontSize: "15px"}}></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                   

                <div className="card border-0">
                    <ul className="nav nav-tabs nav-tabs-v2 px-3">
                        {this.state.tabs.map( (item) =>
                            <li key={item.chave} className="nav-item me-2" >
                                <Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
                                    {item.valor}
                                </Link>
                            </li>
                        )}
                    </ul>
                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            {metas.length > 0 && (
                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{textAlign:"center"}}><b>Data</b></th>
                                            <th style={{textAlign:"center"}}><b>Dia</b></th>
                                            <th style={{textAlign:"center"}}><b>Venda realizada</b></th>
                                            <th style={{textAlign:"center"}}><b>Venda acumulada</b></th>
                                            <th style={{textAlign:"center"}}><b>Meta diário</b></th>
                                            <th style={{textAlign:"center"}}><b>Meta acumulada</b></th>
                                            <th style={{textAlign:"center"}}><b>%</b></th>
                                            <th style={{textAlign:"center"}}><b>Venda vs Meta</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {metas.length > 0 && metas.map((item, idx, arr) => 
                                        
                                                <tr>
                                                    <td style={{textAlign:"center"}}>{this.formatDate(item.date)}</td>
                                                    <td style={{textAlign:"center"}}>{this.diaSemana(item.date)}</td>
                                                    <td style={{textAlign:"center", color:"#0043ff"}}><b>{ item.valor > 0 ? parseFloat(item.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "-"}</b></td>
                                                    <td style={{textAlign:"center"}}>{parseFloat(this.acumulado(idx, arr)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                    
                                                    {item.meta ? (
                                                        <>
                                                            <td style={{textAlign:"center", color:"#0043ff"}}><b>{parseFloat(item.meta).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                            <td style={{textAlign:"center", color:"#ff9900"}}><b>{parseFloat(this.metaAcumulado(idx, arr)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                        </>
                                                    ):(
                                                        <>
                                                            <td style={{textAlign:"center"}}>-</td>
                                                            <td style={{textAlign:"center"}}>-</td>
                                                        </>
                                                    )}

                                                    {item.meta && this.percentDiff(item.valor,item.meta) > 0 && (
                                                        <td style={{textAlign:"center", color:"#0f9e3e"}}><b>{parseInt(this.percentDiff(item.valor,item.meta))}</b></td>
                                                    )}
                                                    {item.meta && this.percentDiff(item.valor,item.meta) <= 0 && (
                                                        <td style={{textAlign:"center", color:"#ff1b30"}}><b>{parseInt(this.percentDiff(item.valor,item.meta))}</b></td>
                                                    )}
                                                    {!item.valor || !item.meta ? (
                                                        <td style={{textAlign:"center"}}>-</td>
                                                    ) : <></>}

                                                    {item.valor && item.meta && this.realDiff(item.valor,item.meta) > 0 && (
                                                        <td style={{textAlign:"center", color:"#0f9e3e"}}><b>{parseInt(this.realDiff(item.valor,item.meta)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                    )}
                                                    {item.valor && item.meta && this.realDiff(item.valor,item.meta) <= 0 && (
                                                        <td style={{textAlign:"center", color:"#ff1b30"}}><b>{parseInt(this.realDiff(item.valor,item.meta)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                    )}
                                                    {!item.valor || !item.meta ? (
                                                        <td style={{textAlign:"center"}}>-</td>
                                                    ) : <></>}
                                                </tr>
                                        
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
               
                <Modal isOpen={this.state.modalMedia} toggle={() => this.setState({ modalNew: false })}>
					<ModalBody>
						<div className="p-4">
							<h3>Criar nova meta mensal</h3>
							
							<p>texto</p>
							
							<div className="row">
                                <div className="form-floating col-md-12 mb-15px" >
									<InputText
										nome="media_mensal"
										setFomrData={this.setMetaMensal}
									/>
									<Label nome="media_mensal" label="Média mensal"/>
								</div>
							</div>
						
                            <button className="btn btn-outline-primary col-md-3 " onClick={(e) => this.hadleModalMediaMensal(e)}>Cancela</button>
							<button className="btn btn-primary col-md-3 offset-md-6"  onClick={(e) => this.saveMeta(e)}>Cadastrar</button>
						</div>
					</ModalBody>
				</Modal>
            </div>
        )
    }

}