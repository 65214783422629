import React from 'react'

import TopPage from '../../components/_organisms/TopPage'

import FormProduto from '../../components/_templates/Form'
import FormCategoria from '../../components/_templates/FormCategoria'
import FormMesa from '../../components/_templates/FormMesa'
import FormOpcional from '../../components/_templates/FormOpcional'
import FormOpcionalSeletor from '../../components/_templates/FormOpcionalSeletor'
import FormAdicionalSeletor from '../../components/_templates/FormAdicionalSeletor'
import FormStock from '../../components/_templates/FormStock'
import FormMotoboy from '../../components/_templates/FormMotoboy'
import FormVoucher from '../../components/_templates/FormVoucher'

export default class EditarProduto extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.info.match.params.model,
            id:this.props.info.match.params.id,
            tabActive: "tab-1"
        }

        this.handleTabs = this.handleTabs.bind(this)
    }

    handleTabs(e){
        e.preventDefault()

        this.setState({tabActive: e.target.dataset.tab})
    }

    render() {
        return (
            <div>
                <TopPage model={this.state.model === 'Motoboys' ? 'Entregadores' : this.state.model} new={this.state.id}>
                    {this.state.model === "Produto" && (
                        <p className="lead">
                            Para começar, informe o nome do produto, uma descrição breve e uma imagem. 
                            Em seguida, escolha a categoria na qual seu produto se encaixa. 
                            Isso ajuda os clientes a encontrarem seu produto de forma mais fácil. 
                            Depois, informe o preço padrão do produto. 
                            Se você tiver uma promoção ou desconto disponível, pode inserir um preço promocional para que os clientes saibam que estão recebendo um bom negócio.
                        </p>
                    )}

                    {this.state.model === "Categoria" && (
                        <p className="lead">
                            O cliente tem a opção de criar categorias e subcategorias para aparecerem no cardápio. 
                            É importante limitar o número de categorias para facilitar a navegação. 
                            Se houver muitos produtos, é recomendável classificá-los em subcategorias, agrupando produtos semelhantes. Por exemplo, uma categoria pode ser "Cervejas" e as subcategorias podem ser "Pale Ale", "IPA", "Red Lager" e "Chopp". 
                            Dessa forma, o cardápio fica mais organizado e fácil de usar.
                        </p>
                    )}
                </TopPage>

                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a href="#tab-1" data-tab="tab-1" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-1" ? "nav-link active" : "nav-link"}>
                            {this.state.id ? "Editar" : "Cadastrar" }
                        </a>
                    </li>
                    {this.state.model === "Produto" && this.state.id && (
                        <>
                            <li className="nav-item" role="presentation">
                                <a href="#tab-2" data-tab="tab-2" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-2" ? "nav-link active" : "nav-link"}>
                                    Opcionais
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a href="#tab-3" data-tab="tab-3" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-3" ? "nav-link active" : "nav-link"}>
                                    Adicionais
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a href="#tab-4" data-tab="tab-4" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive == "tab-4" ? "nav-link active" : "nav-link"}>
                                    Stoque
                                </a>
                            </li>
                        </>
                    )}
                </ul>

                <div className="tab-content panel p-5 rounded-0 rounded-bottom">
                    <div className={this.state.tabActive == "tab-1" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-1">
                        {this.state.model === "Produto" && (
                            <FormProduto model={this.state.model} id={this.state.id} />
                        )}

                        {this.state.model === "Opcional" && (
                            <FormOpcional model={this.state.model} id={this.state.id} />
                        )}

                        {this.state.model === "Categoria" && (
                            <FormCategoria model={this.state.model} id={this.state.id} />
                        )}

                        {this.state.model === "Mesa" && (
                            <FormMesa model={this.state.model} id={this.state.id} />
                        )}

                        {this.state.model === "Motoboys" && (
                            <FormMotoboy model={this.state.model} id={this.state.id} />
                        )}

                        {this.state.model === "Voucher" && (
                            <FormVoucher model={this.state.model} id={this.state.id} />
                        )}
                    </div>

                    {this.state.model === "Produto" && this.state.id && (
                        <>
                            <div className={this.state.tabActive == "tab-2" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-2">
                                <FormOpcionalSeletor model={this.state.model} id={this.state.id} />
                            </div>
                            <div className={this.state.tabActive == "tab-3" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-3">
                                <FormAdicionalSeletor model={this.state.model} id={this.state.id} />
                            </div>
                            <div className={this.state.tabActive == "tab-4" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-4">
                                <FormStock model={this.state.model} id={this.state.id} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}