
import React from 'react'

import { getStruct, saveObj, getItem } from '../../store/api.js'

import DiasFuncionamento from '../_organisms/DiasFuncionamento'
import ValoresProduto    from '../_organisms/ValoresProduto'
import InputImage   from '../_molecules/InputImage'
import DropDown     from '../_atoms/DropDown'
import InputText    from '../_atoms/InputText'
import TextArea     from '../_atoms/TextArea'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormProduto extends React.Component {
    constructor(props) {
        super(props)

        console.log(this.props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            arrcampos:[],
            stock:0,
            data:{
              items:{
                b_ativo: true,
                b_delivery: true
              },
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.setRelationData = this.setRelationData.bind(this)
    }

    async componentDidMount() {
        const struct = await getStruct(this.state.model)
        this.setState({ arrcampos: struct.data })

        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }

        const stock = await getItem('StockProduto', this.state.id)
        this.setState({ stock:stock.data })
    }

    async handleSubmit(event) {
        event.preventDefault()

        let save = true

        if (this.state.data.items["id_categoria"] === undefined) {
            save = false
        }
        if (this.state.data.items["nome"] === undefined) {
                save = false
        }

        if (this.state.data.items["valor"] === undefined) {
            save = false
        }
    
        if (save){
            try {
                await saveObj(this.state.data, this.state.model)
                Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
                window.history.back()
              } catch (e) {
                Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
              }
        } else {
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Valida se você adicionou categoria, nome e valor ao seu produto.', 'top-right')
        }

    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data

            if(name == 'nome') value = value.toUpperCase()

            data.items[name] = value

            this.setState({ data })
        }
    }

    setRelationData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            
            if (data.relations) data.relations[name] = value
            else {
                data.relations = {}
                data.relations[name] = value
            }

            this.setState({ data })
        }
    }

    unsetRelationData = (index) => {
        return {}
    }

    render() {
        const categ = this.state.arrcampos.filter((element) => element.nome === "id_categoria")
        const atual = this.state.data.items
        const atualRelation = this.state.data.relations.Variacao

        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className="col-md-6">

                        <div className='row'>

                            <div className="form-floating mb-15px" style={{width:"30%", float:"left"}}>
                                <InputImage 
                                    nome="id_gb_imagem"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual && atual.imagem ? atual.imagem.imagem : null}
                                    required={true}
                                />
                            </div>


                            <div style={{width:"70%", float:"right"}}>
                                <div className="form-floating mb-15px" >
                                    <InputText
                                        nome="nome"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={atual ? atual.nome : ""}
                                        required={true}
                                    />
                                    <Label nome="nome" label="Nome do Produto"/>
                                </div>

                                <div className="form-floating mb-15px">
                                    <DropDown 
                                        nome="id_categoria"
                                        setFomrData={this.setModelData}
                                        options={categ.length > 0 ? categ[0].options : null}
                                        dadosIniciais={atual ? atual.id_categoria : false}
                                        label="Selecione uma categoria"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="form-floating mb-15px">
                            <TextArea
                                nome="descricao"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.descricao : ""}
                            />
                            <Label nome="descricao" label="Descrição do Produto" textArea={true}/>
                        </div>

                        <h5>Visibilidade</h5>

                        <div className='row mb-30px'  style={{padding:'0 10px'}}>
                            <div className="form-check form-switch col-6">
                                <input className="form-check-input" name="b_ativo" type="checkbox" checked={this.state.data.items['b_ativo']} onChange={() => {this.setModelData("b_ativo", !this.state.data.items['b_ativo'])}} id="ativo_cardapio" />
                                <label className="form-check-label" htmlFor="ativo_cardapio">Ativo no cardápio</label>
                            </div>
                       
                            <div className="form-check form-switch col-6">
                                <input className="form-check-input" name="b_delivery" type="checkbox" checked={this.state.data.items['b_delivery']} onChange={() => {this.setModelData("b_delivery", !this.state.data.items['b_delivery'])}} id="ativo_delivery" />
                                <label className="form-check-label" htmlFor="ativo_delivery">Ativo no delivery</label>
                            </div>
                        </div>

                        <h5>Disponibilidade</h5>

                        <div className="form-floating mb-30px">
                            <DiasFuncionamento
                                nome="dias"
                                labelSeletor="Apresentar produto em dias especificos"
                                labelInformacao="Produto disponivel no cardápio nos dias e horarios:"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual}
                            />
                        </div>

                        <div className="form-floating mb-15px" >
                            <InputText
                                nome="codigo_barras"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.codigo_barras : ""}
                            />
                            <Label nome="codigo_barras" label="Código de barras"/>
                        </div>

                        <div className="form-floating mb-15px" >
                            <InputText
                                nome="Stock"
                                dadosIniciais={this.state.stock}
                                readOnly={true}
                            />
                            <Label nome="Stock" label="Produtos em Stock"/>
                        </div>
                        
                    </div>

                    <div className="col-md-6">
                        <ValoresProduto
                            nome="valor"
                            setFomrData={this.setModelData}
                            setFomrRelation={this.setRelationData}
                            unsetRelationData={this.unsetRelationData}
                            atual={atual}
                            atualRelation={atualRelation}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar Dados</button>
                    </div>
                </div>
            </form>
        )
    }
}