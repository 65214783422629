import React from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AppSettings } from './../../config/app-settings.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DropDown from '../../components/_atoms/DropDown'
import Label        from '../../components/_atoms/Label'
import Helper from '../../config/helper'

// import { ThermalPrint } from '../../servicos/ThermalPrint'
import FormImpressao from '../../components/_templates/FormImpressao'
import { Impressoras } from '../../servicos/Impressoras'

import { getList, getItem, saveObj, removeProduto, confirmaimpressao, getPDVData } from './../../store/api.js';
import Lottie from "lottie-react"
import lottiewating from "../../assets/94030-pizza-process.json"
import lottiepagamento from "../../assets/pagamento-maquina.json"
import lottieProcuraProduto from "../../assets/procurar-produto-pdv.json"
import lottieTrocaMesa from "../../assets/troca-de-mesa.json"
import lottieAbrirMesa from "../../assets/abrir-mesa.json"
import lottiejson from "../../assets/pedidos-vazios-cozinha.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"
import lottieDelivery from "../../assets/produto-entregue.json"
import lottieDeliveryEnviando from "../../assets/enviando-delivery.json"
import lottieAlertaReimpressao from "../../assets/impresao-pedidos-impresora.json"
import lottiePedidoEnviadoCozinhaApp from "../../assets/pedido-enviado-cozinha-app.json"
import lottieAnimationDelivery from "../../assets/animation-deliver.json"
import lottiePreparo from "../../assets/preparo.json"
import lottieDeliveryEntregue from "../../assets/Food delivered.json"
import lottieLoaderComanda from "../../assets/placeholder-preto.json"

import { render } from 'react-thermal-printer'

class Loja extends React.Component {
	static contextType = AppSettings

	constructor(props) {
		super(props);
	
		this.time = '00:00';
		this.state = {
			brand: null,
			login:null,
			qzActive: false,
			posMobileSidebarToggled: false,
			mesas: [],
			mesasDisponiveis: [],
			comanda: null,
			mesaSelecionada:null,
			interval:null,
			mesasbkp:null,
			heartbeat:[],
			audio: new Audio('https://grubicons.s3.us-east-1.amazonaws.com/grub.wav'),
			audio_delivery: new Audio('https://grubicons.s3.us-east-1.amazonaws.com/grub-notifica-PDV.mp3'),
			mute: true,
			modalPagamento: false,
			modalCancelaComanda: false,
			modalPagamentoDelivery: false,
			modalTroca: false,
			modalAbreMesa: false,
			tipoPagamento: 'dinheiro',
			selectedProduto:[],
			pagamentos:[],
			waiting: true,
			handleSetTaxa: true,
			todosProdutos: [],
			filterProdutos: [],
			selectedFromLista: null,
			pedidoQuantidade:1,
			numeroPessoas: 1,
			dashFiltro: "todos",
			dashFiltroMesas: "mesas",
			buscaMesaComanda: "",
			filtroAplicao: [],
			variacao:null,
			opcionaisSelecionados:[],
			adicionaisSelecionados:[],
			complemento:null,
			opcionaisProduto: [],
			adicionaisProduto: [],
			impressoraPedido: null,
			plusInfo:null,
			modalConfigImpressora:false,
			filasImpressao: [],
			comandasAbertas:[],
			modalConfirmacaoReimpressaoGeral:false,
			modalStatusDelivery: false,
			modalCancela:false,
			dadosCancelmento:null,
			senhaPDV:null,
			motivoCancelamento:1,
			socketCoamanda:null,
			loadingComanda:false,
			impressaoAutomatica:null,
			produtoPedido:[],
		}

		this.fetchData = this.fetchData.bind(this)
		this.handleComanda = this.handleComanda.bind(this)
		this.handleFechaComanda = this.handleFechaComanda.bind(this)
		this.handlePagaProduto = this.handlePagaProduto.bind(this)
		this.handlePagaProdutoDelivery = this.handlePagaProdutoDelivery.bind(this)
		this.setHeartBeat = this.setHeartBeat.bind(this)
		this.toggleModal = this.toggleModal.bind(this)
		this.valorComanda = this.valorComanda.bind(this)
		this.valorTaxa = this.valorTaxa.bind(this)
		this.valorComandaTaxa = this.valorComandaTaxa.bind(this)
		this.hadleSelectProduto = this.hadleSelectProduto.bind(this)
		this.changeTipoPagamento = this.changeTipoPagamento.bind(this)
		this.handleSetTaxa = this.handleSetTaxa.bind(this)
		this.valorTotalComanda = this.valorTotalComanda.bind(this)
		this.valorFaltante = this.valorFaltante.bind(this)
		this.valorPago = this.valorPago.bind(this)
		this.toggleModalMesa = this.toggleModalMesa.bind(this)
		this.dropdownMesas = this.dropdownMesas.bind(this)
		this.handleNewMesa = this.handleNewMesa.bind(this)
		this.toggleModalAbreMesa = this.toggleModalAbreMesa.bind(this)
		this.toggleModalProdutos = this.toggleModalProdutos.bind(this)
		this.handleAbrirMesa = this.handleAbrirMesa.bind(this)
		this.handleSelectedFromLista = this.handleSelectedFromLista.bind(this)
		this.mudaQuantidadeCarrinho = this.mudaQuantidadeCarrinho.bind(this)
		this.handleAddPedido = this.handleAddPedido.bind(this)
		this.handlerRemoveItem = this.handlerRemoveItem.bind(this)
		this.dashFiltro = this.dashFiltro.bind(this)
		this.dashFiltroMesas = this.dashFiltroMesas.bind(this)
		this.changeStatus = this.changeStatus.bind(this)
		this.handleCancelaComanda = this.handleCancelaComanda.bind(this)
		this.handleOpcionais = this.handleOpcionais.bind(this)
		this.getItemPrice = this.getItemPrice.bind(this)
		this.handleImpressora = this.handleImpressora.bind(this)
		this.handlePlusInfo = this.handlePlusInfo.bind(this)
		this.handleBusca = this.handleBusca.bind(this)
		this.toggleStatus = this.toggleStatus.bind(this)
		this.imprimeComprovante = this.imprimeComprovante.bind(this)
		this.hadleImpressaoAutimatica = this.hadleImpressaoAutimatica.bind(this)
		this.savePedido = this.savePedido.bind(this)
	}

	async handlePlusInfo(e, id){
		e.preventDefault(); 
		
		if (this.state.plusInfo === id){
			this.setState({plusInfo:null})
		} else {
			this.setState({plusInfo:id})
		}
		
	}

	async handleValidaImpressao(e) {
		e.preventDefault()

		const comanda = this.state.comanda
		let naoImpresso = false

		comanda.pedidos.forEach( (pedido) => {
			pedido.produtos.forEach( (produto) => {
				if(!produto.impresso) {
					naoImpresso = true
				}
			})
		})

		if (naoImpresso){
			await this.handleImpressora(e)
		} else {
			this.setState( {modalConfirmacaoReimpressaoGeral:true} )
		}
	}

	async handleImpressora(e, reimprime = false){
        e.preventDefault()

		const comanda = this.state.comanda
		const filas = this.context.stateFilas

		for (let p in comanda.pedidos) {
			let pedido = comanda.pedidos[p]
			for (let pp in pedido.produtos) {
				let produto = pedido.produtos[pp]
				
				if(!produto.impresso) {
					await confirmaimpressao({id:produto.pivot_id, status:true})
				}
			}
		}

		if ( filas.length > 0 ) {
			for (let i=0; i < filas.length; i++) {
				let fila = filas[i]
			
				const valor = await Helper.montaTempleteImpressao(comanda, this.state.mesaSelecionada, null, fila, reimprime, this.state.selectedProduto)
				const data = await render( valor )
		
				await Impressoras.imprime(this.context, fila.id_impressora, data)
			}
		} else {
			const valor = await Helper.montaTempleteImpressao(comanda, this.state.mesaSelecionada, null, null, reimprime, this.state.selectedProduto)
			const data = await render( valor )
			
			await Impressoras.imprime(this.context, null, data)
		}
		 
		if(reimprime) {
			this.setState( {modalConfirmacaoReimpressaoGeral:false} )
		}
    }

	async handleReImpressao(e, id, produtoId = null){
        e.preventDefault()

		const comanda = this.state.comanda
		const filas = this.context.stateFilas
		const valor = await Helper.montaTempleteImpressao(comanda, this.state.mesaSelecionada, produtoId, null)
		const data = await render( valor )
		

		if ( filas.length > 0 ) {
			const pedidos = comanda.pedidos
			let produto = null
	
			if (pedidos.length > 0 ) {
				pedidos.map( pedido => {
					pedido.produtos.map( dd => {
						if (dd.id === id) {
							produto = dd
						}
					})
				})
			}
	
			const fila = filas.filter( d => d.id == produto.fila)
			await Impressoras.imprime(this.context, fila[0].id_impressora, data)
		} else {
			await Impressoras.imprime(this.context, null, data)
		}
    }

	async handlerRemoveItem(e, pedido, id, pivot, produtoId = null){
		e.preventDefault()

		const comanda = this.state.comanda
		const pedidos = comanda.pedidos
		let produto = null

		if (pedidos.length > 0 ) {
			pedidos.map( pedido => {
				pedido.produtos.map( dd => {
					if (dd.id === id) {
						produto = dd
					}
				})
			})
		}

		this.setState({modalCancela: true, dadosCancelmento:{
			pedido:pedido,
			id: id,
			pivot: pivot,
			produtoId: produtoId,
			produto:produto
		}})
	}

	async executaCancelamento(){

		const pedido = this.state.dadosCancelmento.pedido
		const id = this.state.dadosCancelmento.id
		const pivot = this.state.dadosCancelmento.pivot
		const produtoId = this.state.dadosCancelmento.produtoId

		this.setState({ modalCancela: false })
		
		if (this.state.login.loja.senha_pdv === this.state.senhaPDV) {
			try {
				await removeProduto(pedido, pivot, this.state.motivoCancelamento)

				if (this.context.stateImpressoras.length > 0 && this.state.qzActive) {
					const filas = this.context.stateFilas
					const valor = await Helper.montaTempleteImpressaoCancelamento(this.state.comanda, produtoId, this.state.mesaSelecionada)
					const data = await render( valor )
					

					if (filas.length > 0) {
						const comanda = this.state.comanda
						const pedidos = comanda.pedidos
						let produto = null
				
						if (pedidos.length > 0 ) {
							pedidos.map( pedido => {
								pedido.produtos.map( dd => {
									if (dd.id === id) {
										produto = dd
									}
								})
							})
						}
				
						const fila = filas.filter( d => d.id == produto.fila)
						await Impressoras.imprime(this.context, fila[0].id_impressora, data)
					} else {
						await Impressoras.imprime(this.context, null, data)
					}
				}
				
				const result = await getItem('Comanda', this.state.comanda.id)
				const c = result.data

				c.pedidos.forEach(e => {
					e.produtos.forEach((p, i) => {
						p.token = e.id+"_"+p.id+"_"+i
					})
				})

				
				this.setState({ comanda: c, pagamentos: result.data.pagamentos, modalCancela: false })
				Helper.addNotification('danger', 'Produto Removido', 'Produto removido da comanda de pedidos.', 'top-right')
			} catch (e) {
				console.log(e)
			}

		
		} else {
			Helper.addNotification('danger', 'Acesso invalido', 'Você não tem permissão para realizar esta ação.', 'top-right')
		}

		this.setState({ modalCancela: false })
	}
    
	getItemPrice(item){
		let price_tag = item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? item.valor_promocional : item.valor
		const valorAdicionais = item.adicionais && item.adicionais.length > 0 ? item.adicionais.reduce( (total, i) => {return i.valor ? parseFloat(total) + parseFloat(i.valor) : parseFloat(total)}, 0 ).toFixed(2) : 0

		if ( item.variacao ) {
			price_tag = item.variacao_valor
		}

		return ((parseFloat(price_tag) + parseFloat(valorAdicionais)) * parseInt(item.quantidade)).toFixed(2)
	}

	async dashFiltroMesas(e){
		e.preventDefault()
		const filtro = e.currentTarget.dataset.filtro
		const result = await getList('mesas/pdv')
		const busca = this.state.buscaMesaComanda

		if (busca != "" && busca != null) {
			if (filtro === "mesas") {
				this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 0 && i.identificador.toLowerCase().includes(busca.toLowerCase()) )})
			}

			if (filtro === "comandas") {
				this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 1 && i.identificador.toLowerCase().includes(busca.toLowerCase()) )})
			}
		} else {
			if (filtro === "mesas") {
				this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 0 )})
			}

			if (filtro === "comandas") {
				this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 1 )})
			}
		}
		
		this.setState({ dashFiltroMesas: filtro })
	}

	dashFiltro(e){
		e.preventDefault()

		getList('mesas/pdv/completo').then(result => {
			const comandasAbertas = result.filter( i => {
				if (i.b_delivery === 1 && i.pedidos.length > 0 && i.entrega.length > 0) return i
				else if (i.b_delivery === 0 )  return i
			})

			let filtro = comandasAbertas

			if (this.state.dashFiltro === "dinein") {
				filtro = filtro.filter( i => i.b_delivery === 0 && i.b_takeaway === 0)
			} 

			if (this.state.dashFiltro === "delivery") {
				filtro = filtro.filter( i => i.b_delivery === 1 )
			} 

			if (this.state.dashFiltro === "takeaway") {
				filtro = filtro.filter( i => i.b_takeaway === 1 )
			} 

			this.setState({ filtroAplicao: filtro})
		})
	
		this.setState({ dashFiltro: e.currentTarget.dataset.filtro })
	}
  
	dropdownMesas(){
		if (this.state.mesas.length > 0) {
			const arr = []
			const mesas = this.state.mesasDisponiveis

			mesas.forEach(item => {
				arr.push({id: item.id, label: item.identificador})
			})

			return arr
		} else {
			return 0
		}
	}

	async toggleStatus(e){
		e.preventDefault()
		this.setState({modalStatusDelivery: !this.state.modalStatusDelivery})
	}

	async changeStatus(e){
		e.preventDefault()

		const status = e.currentTarget.dataset.status

		if (status === "producao") {
			this.handleImpressora(e)
		}

		if (status === "caminho" && this.context.stateImpressoras.length > 0 && this.state.qzActive) {
			const produtos = Array()
			const comanda = this.state.comanda
			const fila = this.context.stateFilas.filter( d => d.b_financeiro )
			let totalProdutos = 0

			comanda.pedidos.forEach(element => {
				element.produtos.forEach(p => {

					totalProdutos++
					p.pedido = element.id
					produtos.push(p)
					
				})
			})

			const taxaEntrega = this.state.comanda && this.state.comanda.b_delivery === 1 && this.state.comanda.entrega ? this.state.comanda.entrega.zonaEntrega.valor : 0
			const valor = await Helper.impressaoDemostrativo(this.state.login, produtos, this.valorComanda().toString(), this.valorComandaTaxa().toString(), taxaEntrega, comanda, this.state.mesaSelecionada, totalProdutos, true, this.calculaDesconto())
			const data = await render( valor )
	
			await Impressoras.imprime(this.context, fila[0].id_impressora, data)
		}

		await saveObj({items:{status: status, id:this.state.comanda.id}}, "Comanda")
	}

	async handleModalCancelaComanda(e){
		e.preventDefault()

		this.setState({ modalCancelaComanda: !this.state.modalCancelaComanda })
	}

	async handleCancelaComanda(e){
		e.preventDefault()

		const produtos = Array()
		const comanda = this.state.comanda

		comanda.pedidos.forEach(element => {
			element.produtos.forEach(p => {
				p.pedido = element.id
				produtos.push(p)
			})
		})

		await saveObj({items:{
						tipo: 'cancelado', 
						id_comanda: comanda.id
					}, relations: {Produtos: produtos} 
				}, "pagamento/cancela")

		this.setState({ selectedProduto: [], modalStatusDelivery:false, modalCancelaComanda: !this.state.modalCancelaComanda,  comanda: null, pagamentos: null })
		
	}

	async handleNewMesa(mesa, value){
		await saveObj({items:{id: this.state.comanda.id, id_mesa:value}}, "trocamesa")
		await saveObj({items:{id: this.state.comanda.id_mesa, status: "aberta"}}, "Mesa")
		await saveObj({items:{id: value, status: "ocupada"}}, "Mesa")
		const mesas = this.state.mesasDisponiveis.filter( item => item.id === value)
		this.setState({ mesaSelecionada: mesas[0].identificador })
	}

	async handleAbrirMesa(mesa){
		if (this.state.mesaSelecionada === "takeaway"){
			await saveObj({items:{b_takeaway: true, status: "aberta"}}, "Comanda")
		} else {
			const mesas = this.state.mesasDisponiveis.filter( item => item.identificador === mesa)

			await saveObj({items:{id_mesa: mesas[0].id, status: "aberta"}}, "Comanda")
			await saveObj({items:{id: mesas[0].id, status: "ocupada"}}, "Mesa")
		}
	}

	handleSetTaxa() {
		this.setState({handleSetTaxa: !this.state.handleSetTaxa})
	}

	handleStartTime() {
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var a;
		m = (m < 10) ? "0" + m : m;
		a = (h > 11) ? 'pm' : 'am';
		h = (h > 12) ? h - 12 : h;
		
		this.time = h + ":" + m + a;
		setTimeout(this.handleStartTime, 500);
	}
	
	togglePosMobileSidebar() {
		this.setState({ posMobileSidebarToggled: !this.state.posMobileSidebarToggled });
	}

	setHeartBeat(data){
		const heartbeat = Array()

		for(let i in data) {
			const mesasbkp = this.state.mesasbkp
			const pedidosAtual = data[i].pedidos.length
			let  pedidosAnterior = 0

			if (mesasbkp && mesasbkp.length > 0) {
				pedidosAnterior =  mesasbkp[i] && mesasbkp[i].pedidos.length ? mesasbkp[i].pedidos.length : 0
				
				if (pedidosAtual > pedidosAnterior)  {
					heartbeat.push(parseInt(i))
					
				}
			} else if (pedidosAtual > 0) {
				heartbeat.push(parseInt(i))
			}
		}

		this.setState({heartbeat:heartbeat})
	}

	fetchData() {
		const socket = getPDVData()
		const socket2 = getPDVData()
		
		socket.onopen = () => {
			socket.send(JSON.stringify({ tipo: 'pdv' }))
		}

		socket2.onopen = () => {
			socket2.send(JSON.stringify({ tipo: 'completo' }))
		}

		socket.onmessage = (event) => {
			const result = JSON.parse(event.data)

			const filtro = this.state.dashFiltroMesas
			const busca = this.state.buscaMesaComanda

			if (busca != "" && busca != null) {
				if (filtro === "mesas") {
					this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 0 && i.identificador.toLowerCase().includes(busca.toLowerCase()) )})
				}

				if (filtro === "comandas") {
					this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 1 && i.identificador.toLowerCase().includes(busca.toLowerCase()) )})
				}
			} else {
				if (filtro === "mesas") {
					this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 0 )})
				}

				if (filtro === "comandas") {
					this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 1 )})
				}
			}
		}

		socket2.onmessage = (event) => {
			const result = JSON.parse(event.data)

			const comandasAbertas = result.filter( i => {
				if (i.b_delivery === 1 && i.pedidos.length > 0 && i.entrega.length > 0) return i
				else if (i.b_delivery === 0 )  return i
			})

			let filtro = comandasAbertas

			if (this.state.dashFiltro === "dinein") {
				filtro = filtro.filter( i => i.b_delivery === 0 && i.b_takeaway === 0)
			} 

			if (this.state.dashFiltro === "delivery") {
				filtro = filtro.filter( i => i.b_delivery === 1 )
			} 

			if (this.state.dashFiltro === "takeaway") {
				filtro = filtro.filter( i => i.b_takeaway === 1 )
			}

			this.setState({ mesasbkp: this.state.mesas , mesas: filtro, filtroAplicao: filtro, heartbeat:[], waiting:false, comandasAbertas:comandasAbertas})
			this.setHeartBeat(result)
		}

		const comanda = this.state.comanda
		
		if (comanda && comanda.id){
			this.setState({ loadingComanda: true })
			let socket3 = this.state.socketCoamanda 
			
			if (socket3) socket3.close()
				
			socket3 = getPDVData()

			this.setState({ socketCoamanda: socket3 })

			socket3.onopen = () => {
				socket3.send(JSON.stringify({ tipo: 'comanda', id: comanda.id }))
			}

			socket3.onmessage = (event) => {
				const comanda = JSON.parse(event.data)

				comanda.pedidos.forEach(e => {
					e.produtos.forEach((p,i) => {
						p.token = e.id+"_"+p.id+"_"+i
					})
				})

				if (comanda.status !== "fechada"){
					this.setState({ comanda: comanda, pagamentos: comanda.pagamentos })
				} else {
					this.setState({ comanda: null, pagamentos: null })
					socket3.close()
				}

				this.setState({ loadingComanda: false })
			}
		}

		if (this.state.login.loja.impressao_automatica){
			setInterval(() => {this.hadleImpressaoAutimatica()}, 5000)
			setInterval(() => {this.limpaFilaBKP()}, 2500)
		}

		getList('Produto', {page:0}).then(result => {
			const ativos = result.filter((a) => a.b_ativo === 1)
			this.setState({ todosProdutos: ativos, filterProdutos: ativos  })
		})
	}
  
	async componentDidMount() {
		this.context.handleSetAppSidebarNone(true)
		this.context.handleSetAppHeaderNone(true)
		this.context.handleSetAppContentFullHeight(true)
		this.context.handleSetAppContentClass('p-0')
		this.handleStartTime()

		const ip = await Impressoras.QzStartConnection()
		this.setState({qzActive: ip})
		await Impressoras.QzCloseConnection()

		// setInterval(() => {
		// 	Impressoras.isQzAtivo().then((r) =>{
		// 		this.setState({qzActive: r})
		// 	})
		// }, 7000)
		
		let user = this.context.stateUserdata
		if(!user){
			await this.context.saveUserData()
			user = this.context.stateUserdata
		}

		this.setState({brand: user.brand ? user.brand : "", login: user})

		this.fetchData()
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false)
		this.context.handleSetAppHeaderNone(false)
		this.context.handleSetAppContentFullHeight(false)
		this.context.handleSetAppContentClass('')
	}

	async handleComanda(e){
		e.preventDefault()

		//reseta valores
		this.setState({ selectedProduto: [], pagamentos: [] })

		let comanda = e.currentTarget.dataset.comanda
		let mesa = e.currentTarget.dataset.mesa
		this.setState({ mesaSelecionada: mesa })

		if (comanda) {
			this.setState({ mesaSelecionada: mesa, loadingComanda: true })
			
			const result = await getItem('Comanda', comanda)
			const c = result.data

			c.pedidos.forEach(e => {
				e.produtos.forEach((p,i) => {
					p.token = e.id+"_"+p.id+"_"+i
				})
			})

			this.setState({ comanda: c, pagamentos: result.data.pagamentos, loadingComanda: false })
		
			let socket = this.state.socketCoamanda 
			
			if (socket) socket.close()
			
			socket = getPDVData()
			this.setState({ socketCoamanda: socket })

			socket.onopen = () => {
				socket.send(JSON.stringify({ tipo: 'comanda', id: comanda }))
			}

			socket.onmessage = (event) => {
				const c = JSON.parse(event.data)
				console.log(event)
				c.pedidos.forEach(e => {
					e.produtos.forEach((p,i) => {
						p.token = e.id+"_"+p.id+"_"+i
					})
				})

				if (c.status !== "fechada"){
					this.setState({ comanda: c, pagamentos: c.pagamentos })
				} else {
					this.setState({ comanda: null, pagamentos: null })
					socket.close()
				}
			}
		} else {
			this.handleAbrirMesa(mesa)
		}
	}

	async imprimeComprovante(e){
		e.preventDefault()

		if ( this.context.stateImpressoras.length > 0 && this.state.qzActive) {
			const selectedProduto = this.state.selectedProduto
			const produtos = Array()
			const comanda = this.state.comanda
			const fila = this.context.stateFilas.filter( d => d.b_financeiro )
			let totalProdutos = 0

			comanda.pedidos.forEach(element => {
				element.produtos.forEach(p => {
					totalProdutos++

					if (selectedProduto.length > 0){
						if (selectedProduto.includes(p.token)) {
							p.pedido = element.id
							produtos.push(p)
						}
					} else {
						p.pedido = element.id
						produtos.push(p)
					}
				})
			})
			const taxa =  this.state.handleSetTaxa && this.state.login.loja.b_taxa_servico ? true : false

			const valor = await Helper.impressaoDemostrativo(this.state.login, produtos, this.valorComanda().toString(), this.valorComandaTaxa().toString(), this.valorTaxa().toString(), comanda, this.state.mesaSelecionada, totalProdutos, taxa, this.calculaDesconto())
			const data = await render( valor )
			
			if (this.context.stateFilas.length > 0 ) {
				await Impressoras.imprime(this.context, fila[0].id_impressora, data)
			} else {
				await Impressoras.imprime(this.context, null, data)
			}
		}
	}

	async toggleModal(e){
		e.preventDefault()

		if (!this.state.modalPagamento && this.context.stateImpressoras.length > 0 && this.state.qzActive) {
			const selectedProduto = this.state.selectedProduto
			const produtos = Array()
			const comanda = this.state.comanda
			const fila = this.context.stateFilas.filter( d => d.b_financeiro )
			let totalProdutos = 0

			comanda.pedidos.forEach(element => {
				element.produtos.forEach(p => {
					totalProdutos++

					if (selectedProduto.length > 0){
						if (selectedProduto.includes(p.token)) {
							p.pedido = element.id
							produtos.push(p)
						}
					} else {
						p.pedido = element.id
						produtos.push(p)
					}
				})
			})
			const taxa =  this.state.handleSetTaxa && this.state.login.loja.b_taxa_servico ? true : false

			const valor = await Helper.impressaoDemostrativo(this.state.login, produtos, this.valorComanda().toString(), this.valorComandaTaxa().toString(), this.valorTaxa().toString(), comanda, this.state.mesaSelecionada, totalProdutos, taxa, this.calculaDesconto())
			const data = await render( valor )
			
			if (this.context.stateFilas.length > 0 ) {
				await Impressoras.imprime(this.context, fila[0].id_impressora, data)
			} else {
				await Impressoras.imprime(this.context, null, data)
			}
		}
		
		this.setState({ modalPagamento: !this.state.modalPagamento })
	}

	toggleModalMesa(e){
		e.preventDefault()
		
		this.setState({ modalTroca: !this.state.modalTroca })
	}

	async toggleModalPagamentoDelivery(e){
		e.preventDefault()
		this.setState({ modalStatusDelivery: !this.state.modalStatusDelivery, modalPagamentoDelivery: !this.state.modalPagamentoDelivery })
	}

	toggleModalAbreMesa(e) {
		e.preventDefault()

		this.setState({ modalAbreMesa: !this.state.modalAbreMesa })
	}

	toggleModalProdutos(e){
		e.preventDefault()

		this.setState({ variacao:null, opcionaisProduto: [], adicionaisProduto: [], opcionaisSelecionados:[], adicionaisSelecionados:[], complemento:null })

		this.setState({ toggleModalProdutos: !this.state.toggleModalProdutos })
	}

	async handlePagaProduto(e){
		e.preventDefault()

		const selectedProduto = this.state.selectedProduto
		const produtos = Array()
		const comanda = this.state.comanda
		const taxa = this.state.login && this.state.login.loja && this.state.login.loja.taixa_servico ? this.state.login.loja.taixa_servico : 10

		comanda.pedidos.forEach(element => {
			element.produtos.forEach(p => {
				if (selectedProduto.length > 0){
					if (selectedProduto.includes(p.token)) {
						p.pedido = element.id
						produtos.push(p)
					}
				} else {
					p.pedido = element.id
					produtos.push(p)
				}
			})
		})

		await saveObj({items:{
				tipo: this.state.tipoPagamento, 
				b_taxa: this.state.handleSetTaxa,
				valor_taxa: taxa,
				b_taxa_entrega: false,
				valor_taxa_entrega:0,
				valor: this.valorTotalComanda().toString(), 
				id_comanda: comanda.id
			}, relations: {Produtos: produtos} 
		}, "pagamento")


		const result = await getItem('Comanda', comanda.id)	
					
		const comandat = result.data

		comandat.pedidos.forEach(e => {
			e.produtos.forEach( (p, i) => {
				p.token = e.id+"_"+p.id+"_"+i
			})
		})

		this.setState({ selectedProduto: [], modalPagamento: !this.state.modalPagamento,  comanda: comandat, pagamentos: result.data.pagamentos })		
	}

	async handlePagaProdutoDelivery(e){
		e.preventDefault()

		const produtos = Array()
		const comanda = this.state.comanda
		const taxaEntrega = this.state.comanda && this.state.comanda.b_delivery === 1 && this.state.comanda.entrega ? this.state.comanda.entrega.zonaEntrega.valor : 0

		comanda.pedidos.forEach(element => {
			element.produtos.forEach(p => {
					p.pedido = element.id
					produtos.push(p)
			})
		})

		await saveObj({items:{
						tipo: this.state.tipoPagamento, 
						b_taxa: false,
						valor_taxa: 0,
						b_taxa_delivery: true,
						valor_taxa_entrega:taxaEntrega,
						valor: this.valorTotalComanda().toString(), 
						id_comanda: comanda.id
					}, relations: {Produtos: produtos} 
				}, "pagamento")


		const result = await getItem('Comanda', comanda.id)	
					
		const comandat = result.data

		this.setState({ selectedProduto: [], modalPagamentoDelivery: !this.state.modalPagamentoDelivery,  comanda: comandat, pagamentos: result.data.pagamentos })
	}

	async handleFechaComanda(e){
		e.preventDefault()

		await saveObj({items:{id: this.state.comanda.id, status: "fechada"}}, "Comanda")
		await saveObj({items:{id: this.state.comanda.id_mesa, status: "aberta"}}, "Mesa")

		this.setState({ comanda: null, mesaSelecionada: null })
	}

	changePedidos(item){
		const mesasbkp = this.state.mesasbkp
		const audio = this.state.audio
		const audio_delivery = this.state.audio_delivery
		const mute = this.state.mute

		const pedidosAtual = item.pedidos.length > 0 ? item.pedidos.length : 0
		const pedidoBkp = mesasbkp.filter( i => i.id === item.id )

		let result = "table-info-container"

		if (pedidoBkp[0] && pedidoBkp[0].pedidos && pedidoBkp[0].pedidos.length > 0) {
			let pedidosAnterior =  pedidoBkp[0].pedidos.length

			if (pedidosAtual > pedidosAnterior)  {
				if (!mute) {
					if (item.b_delivery) audio_delivery.play()
					else audio.play()
				}
				result = "table-info-container orange animate__heartBeat"
			}
		} else if (pedidosAtual > 0) {
			if (!mute) {
				if (item.b_delivery) audio_delivery.play()
				else audio.play()
			}
			result =  "table-info-container orange animate__heartBeat"
		}

		return result
	}

	hadleSelectProduto(id){
		const selectedProduto = this.state.selectedProduto
		const exist = selectedProduto.indexOf(id)
	
		if (exist >= 0) {
			selectedProduto.splice(exist, 1)
		} else {
			selectedProduto.push(id)
		}

		this.setState({selectedProduto: selectedProduto})
	}

	produtoAberto(){
		let result = false
		const comanda = this.state.comanda

		comanda.pedidos.forEach(element => {
			element.produtos.forEach(p => {
				if (!p.pagamento){
					result = true
				}
			})
		})

		return result
	}

	valorComanda(){
		const selectedProduto = this.state.selectedProduto
		const produtos = Array()
		const comanda = this.state.comanda

		comanda.pedidos.forEach(element => {
			element.produtos.forEach(p => {
				if (selectedProduto && selectedProduto.length > 0){
					if (selectedProduto.includes(p.token)) {
						produtos.push(p)
					}
				} else {
					produtos.push(p)
				}
			})
		})

		return produtos.reduce((total, item) => { 
		
			const totalItem = this.getItemPrice(item)
			const val = (parseFloat(total) + parseFloat(totalItem)).toFixed(2) 

			return val
		
		}, 0)
	}

	valorTotalComanda(){
		const selectedProduto = this.state.selectedProduto
		const produtos = Array()
		const comanda = this.state.comanda
		const taxaEntrega = this.state.comanda && this.state.comanda.b_delivery === 1 && this.state.comanda.entrega ? this.state.comanda.entrega.zonaEntrega.valor : 0
		let total = 0

		comanda.pedidos.forEach(element => {
			element.produtos.forEach(p => {
				if (selectedProduto.length > 0){
					if (selectedProduto.includes(p.token)) {
						p.pedido = element.id
						produtos.push(p)
					}
				} else {
					if (!p.pagamento) {
						p.pedido = element.id
						produtos.push(p)
					}
				}
			})
		})

		const valorProdutos = produtos.reduce((total, item) => { 
			const totalItem = this.getItemPrice(item)
			const val = (parseFloat(total) + parseFloat(totalItem)).toFixed(2) 

			return val
		}, 0)

		if (this.state.comanda.b_delivery === 1){
			total = (parseFloat(valorProdutos) + parseFloat(taxaEntrega)).toFixed(2) 
		} else {
			const valorTaxa = this.state.login && this.state.login.loja && this.state.login.loja.taixa_servico ? this.state.login.loja.taixa_servico : 10 
			total = this.state.handleSetTaxa && this.state.login.loja.b_taxa_servico ? (parseFloat(valorProdutos) + (parseFloat(valorProdutos) * valorTaxa) / 100).toFixed(2) : parseFloat(valorProdutos)
		}


		if (comanda.voucher) {
			const desconto = (total * (comanda.voucher.valor / 100))
			return total - parseFloat(desconto)
		} else {
			return total
		}
	}

	valorTaxa(){
		let result = this.valorComanda()
		let valor = this.state.login && this.state.login.loja && this.state.login.loja.taixa_servico ? this.state.login.loja.taixa_servico : 10 

		return ((parseFloat(result) * valor) / 100).toFixed(2)
	}

	valorComandaTaxa(){
		return this.state.handleSetTaxa && this.state.login.loja.b_taxa_servico? 
					(parseFloat(this.valorComanda()) + parseFloat(this.valorTaxa())).toFixed(2)
				:
					parseFloat(this.valorComanda())
	}

	valorFaltante(){
		let result = parseFloat(this.valorTotalComanda())
		const valorPordutosSelcionados = this.state.handleSetTaxa && this.state.login.loja.b_taxa_servico ? parseFloat(this.valorComanda()) + parseFloat(this.valorTaxa()) : parseFloat(this.valorComanda())

		if (this.state.selectedProduto.length > 0)  result -= parseFloat(valorPordutosSelcionados)
		if (this.valorPago() > 0)  result -= parseFloat(this.valorPago())
		
		return result <= 0 ? 0 : result.toFixed(2)
	}

	valorPago(){
		const pagamentos = this.state.pagamentos
		
		return pagamentos.reduce((total, item) => { return (parseFloat(total) + parseFloat(item.valor)).toFixed(2) }, 0)
	}

	changeTipoPagamento(e){
		e.preventDefault()

		let tipo = e.currentTarget.dataset.tipe
	
		this.setState({ tipoPagamento: tipo })
	}

	async handleSelectedFromLista(e, item){
		e.preventDefault()

		const opcionaisProduto = await getItem('produto-opcional', item.id)
		const adicionaisProduto = await getItem('produto-adicional', item.id)

		const variacao = item.variacao && item.variacao.length > 0 ? item.variacao[0] : null

		this.setState({ variacao: variacao, selectedFromLista: item, filterProdutos: this.state.todosProdutos, opcionaisProduto: opcionaisProduto.data, adicionaisProduto: adicionaisProduto.data })
	}

	mudaQuantidadeCarrinho(novoValor){
		const items = parseInt(this.state.pedidoQuantidade)

		if(novoValor === 'add') {
			this.setState({ pedidoQuantidade:  items + 1 })
		} else if (items > 1) {
			this.setState({ pedidoQuantidade:  items - 1 })
		}
	}

	async handleSetStatus(e, id, status){
		e.preventDefault()

		await saveObj({pivot: id, status: status}, "pedido/produto/entregue")

		const result = await getItem('Comanda', this.state.comanda.id)
		const c = result.data

		c.pedidos.forEach(e => {
			e.produtos.forEach( (p,i) => {
				p.token = e.id+"_"+p.id+"_"+i
			})
		})

		this.setState({ comanda: c, pagamentos: result.data.pagamentos })
	}

	verifyDays(value) {
		let result = false
		const d = new Date()

		const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]
		const binario = '0x'+(value >>> 0).toString(2).padStart(7, 0)

		if (!value) result = true
		else result = !!(weekday[d.getDay()] & binario)
		
		return result
	}

	async handleAddPedido(e, item){
		e.preventDefault()
		const obj = structuredClone(item)
		const produtosPedido = this.state.produtoPedido

		let price_tag = item.b_promocao && item.valor_promocional && this.verifyDays(item.dias_promocao) ? item.valor_promocional : item.valor

		if ( item.variacao.length > 0 ) {
			price_tag = this.state.variacao.valor
		}

		const valorAdicionais = this.state.adicionaisSelecionados.reduce( (total, i) => {return parseFloat(total) + parseFloat(i.valor)}, 0 ).toFixed(2)
		
		obj.quantidade = this.state.pedidoQuantidade
		obj.variacao = this.state.variacao ? [this.state.variacao] : []
		obj.valor = price_tag
		obj.opcionais = this.state.opcionaisSelecionados
		obj.adicionais = this.state.adicionaisSelecionados
		obj.totalAdicional = valorAdicionais
		obj.valorTotal = (parseFloat(price_tag) + parseFloat(valorAdicionais)).toFixed(2)
		obj.complemento = this.state.complemento
		produtosPedido.push(obj)

		this.setState({ variacao:null, complemento:null, pedidoQuantidade:1, opcionaisSelecionados:[], adicionaisSelecionados:[], opcionaisProduto: [], adicionaisProduto: [], selectedFromLista: null })
		Helper.addNotification('success', 'Produto adicionado', 'Para continuar incluindo produtos use a busca de produtos.', 'top-left')
		this.setState({ produtoPedido: produtosPedido })
	}

	async savePedido(){
		const produtosPedido = this.state.produtoPedido
		let total = 0

		produtosPedido.forEach( (obj) => {
			total += (parseFloat(obj.valorTotal) * parseInt(obj.quantidade))
		})
		
		const data = {
			valor:total.toFixed(2), 
			itens: produtosPedido,
			id_comanda: this.state.comanda.id,
			mesa: this.state.mesaSelecionada,
		 }

		await saveObj(data, "pedido")

		const result = await getItem('Comanda', this.state.comanda.id)	
					
		const comandat = result.data

		comandat.pedidos.forEach(e => {
			e.produtos.forEach( (p, i)=> {
				p.token = e.id+"_"+p.id+"_"+i
			})
		})

		this.setState({ produtoPedido:[], variacao:null, complemento:null,  opcionaisSelecionados:[], adicionaisSelecionados:[], opcionaisProduto: [], adicionaisProduto: [], pedidoQuantidade: 1, selectedFromLista: null, comanda: comandat, pagamentos: result.data.pagamentos })
		Helper.addNotification('success', 'Pedido adicionado', 'Seus produtos já estão registrados.', 'top-left')
		this.setState({ toggleModalProdutos: !this.state.toggleModalProdutos })
	}

	handleFilterProdutos(e){
		e.preventDefault()
		const busca = e.target.value

		const filter = this.state.todosProdutos.filter(item => { 
			return item.nome.toLowerCase().includes(busca.toLowerCase()) ? item : null
		})

		this.setState({ filterProdutos: filter })
	}

	handleOpcionais(item, tipo = true){
		let op = tipo ? this.state.opcionaisSelecionados : this.state.adicionaisSelecionados
		
		if ( op.find(i => {return i.nome === item.nome}) ){
			op = op.filter(element => element !== item)
		} else {
			op.push(item)
		}

		const data = tipo ? {opcionaisSelecionados: op} : {adicionaisSelecionados: op}
		
		this.setState( data )
	}

	handleComentario(valor){
		if (valor.length < 250) {
			this.setState( {complemento: valor} )
		}
	}

	modalConfigImpressora(e){
		e.preventDefault()

		this.setState( {modalConfigImpressora: !this.state.modalConfigImpressora} )
	}

	async hadleImpressaoAutimatica(){
		const ls = localStorage.getItem('filaimpressao')
		const lsbkp = localStorage.getItem('filaimpressao_bkp')
		const fifo = ls ? JSON.parse(ls) : []
		const fifo_bkp = lsbkp ? JSON.parse(lsbkp) : []
		const atual = this.state.impressaoAutomatica

		if (!atual && fifo && fifo.length > 0 && !fifo_bkp.find( (e) => fifo[0].id == e.id) ) {
			this.setState({impressaoAutomatica: fifo[0].id})
			fifo_bkp.push({id: fifo[0].id})
			localStorage.setItem('filaimpressao_bkp', JSON.stringify(fifo_bkp))

			await this.imprimeProdutos(fifo[0])

			const newArr = fifo.shift()

			if (newArr.length > 0) localStorage.setItem('filaimpressao', JSON.stringify(newArr))
			else localStorage.setItem('filaimpressao', JSON.stringify([]))
		}
	}

	limpaFilaBKP(){
		const lsbkp = localStorage.getItem('filaimpressao_bkp')
		const ls = localStorage.getItem('filaimpressao')
		const fifo_bkp = lsbkp ? JSON.parse(lsbkp) : []
		const fifo = ls ? JSON.parse(ls) : []
		const newArr = []

		if (fifo_bkp.length > 0 && fifo.length > 0) {

			for(let i in fifo_bkp){
				
				if(fifo.find((e) => e.id == fifo_bkp[i].id)){
					newArr.push(fifo_bkp[i])
				}
			}

			localStorage.setItem('filaimpressao_bkp', JSON.stringify(newArr))
		} else {
			localStorage.setItem('filaimpressao_bkp', JSON.stringify(newArr))
		}

	}

	async imprimeProdutos(comanda){
		const produtos = []

		comanda.pedidos.forEach(e => {
			e.produtos.forEach(p => {
				if(!p.impresso) {
					produtos.push(p)
				}
			})
		})

		if (this.state.qzActive) {
			const filas = this.context.stateFilas

			for (let p in produtos) {
				let produto = produtos[p]
				
				if(!produto.impresso) {
					await confirmaimpressao({id:produto.pivot_id, status:true})
				}
			}

			if ( filas.length > 0 ) {
				for (let i in filas) {
					let fila = filas[i]
				
					const valor = await Helper.montaTempleteImpressaoAutomatica(comanda, this.state.mesaSelecionada, fila, produtos)
					const data = await render( valor )
					await Impressoras.imprime(this.context, fila.id_impressora, data)
				}
			} else {
				const valor = Helper.montaTempleteImpressaoAutomatica(comanda, this.state.mesaSelecionada, null, produtos)
				const data = render( valor )

				await Impressoras.imprime(this.context, null, data)
			}

			this.setState({impressaoAutomatica: null})
		}
	}

	hasImpressao(comanda){
		let naoImpresso = 0

		comanda.pedidos.forEach(e => {
			e.produtos.forEach(p => {
				if(!p.impresso) {
					naoImpresso++
				}
			})
		})

		if (naoImpresso > 0){
			const ls = localStorage.getItem('filaimpressao')
			const lsbkp = localStorage.getItem('filaimpressao_bkp')
			const fifo_bkp = lsbkp ? JSON.parse(lsbkp) : []
			let fifo = ls ? JSON.parse(ls) : null

			if (fifo && fifo.length > 0 ) {
				if (!fifo.find( (el) => el.id === comanda.id ) && !fifo_bkp.find( (e) => e.id == comanda.id)){
					fifo.push(comanda)
				}
			} else {
				fifo = [comanda]
			}

			localStorage.setItem('filaimpressao', JSON.stringify(fifo))
		}

		return naoImpresso > 0 ? (<i className="fa fa-print bg-red text-white" style={{padding:"5px", borderRadius:"50%", position:"absolute", top:"10px", right:"10px"}}></i>) : null 
	}

	busca(event) {
		event.preventDefault()

		this.handleBusca(event)
	}

	handleBusca(e){
		const busca = e.target.value

		this.setState({buscaMesaComanda: busca})

		getList('mesas/pdv').then(result => {
			const filtro = this.state.dashFiltroMesas
	
			if (filtro === "mesas") {
				this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 0 && i.identificador.toLowerCase().includes(busca.toLowerCase()) )})
			}

			if (filtro === "comandas") {
				this.setState({ mesasDisponiveis: result.filter( i => i.status === "aberta" && i.b_comanda === 1 && i.identificador.toLowerCase().includes(busca.toLowerCase()) )})
			}
		})
	}

	getImage(item){
		let url = "https://grubupload.s3.amazonaws.com/"
		
		if (item.imagem) {
			url += item.imagem.imagem
		} else {
			url = "https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg"
		}

		return  url
	}

	calculaDesconto(){
		const comanda = this.state.comanda
		if (comanda.voucher) {
			const taxaEntrega = this.state.comanda && this.state.comanda.b_delivery === 1 && this.state.comanda.entrega ? this.state.comanda.entrega.zonaEntrega.valor : 0

			const total = parseFloat(this.valorComanda()) + parseFloat(taxaEntrega)
			const desconto = (total * (comanda.voucher.valor / 100)) * -1

			return desconto
		} else {
			return 0
		}
	}
  
	render() {
		const devicesInativos = this.context.stateImpressoras
		const taxa = this.state.login && this.state.login.loja && this.state.login.loja.taixa_servico ? this.state.login.loja.taixa_servico : 10
		const taxaEntrega = this.state.comanda && this.state.comanda.b_delivery === 1 && this.state.comanda.entrega ? this.state.comanda.entrega.zonaEntrega.valor : 0

		return (
			<div className="vh-100">
				<div style={{overflow:"hidden"}} className={'pos pos-counter ' + (this.state.posMobileSidebarToggled ? 'pos-mobile-sidebar-toggled' : '')} id="pos-counter">
					<div className="pos-counter-header">
						<div className="nav" style={{justifyContent:'flex-start', width:'45%'}}>
							<div className="nav-item">
								<div className="logo" style={{marginRight:'15px'}}>
									{this.state.login && (
										<Link to="/dashboard">
											<div className="logo-img"><img alt="" src={this.state.login.imagem_perfil && this.state.login.imagem_perfil && ("https://grubupload.s3.amazonaws.com/"+this.state.login.imagem_perfil.imagem)} /></div>
											<div className="logo-text">{this.state.login.loja.nome}</div>
										</Link>
									)}
								</div>
							</div>
							
							<div className="nav-item">
								<div className="form-check form-switch">	
									<input className="form-check-input form-check-input-pos" type="checkbox" onClick={() => this.setState({mute: !this.state.mute})} id={"checkbox_mute"} checked={!this.state.mute} />
									<label className="form-check-label" htmlFor={"checkbox_mute"}>{this.state.mute ? "Alerta sonoro desativado" : "Alerta sonoro ativado"}</label>
								</div>
							</div>
						</div>				
						<div className="time" id="time"  style={{width:'10%'}}>{this.time}</div>
						<div className="nav" style={{width:'45%'}}>

							{this.state.qzActive ? (
								<div className="nav-item">
									<div className="btn btn-outline-success" style={{ marginRight:'10px'}} >
										<i className="fa-solid fa-print"></i>  QZ Tray Conectado
									</div>
								</div>
							):(
								<div className="nav-item">
									<div className="btn btn-outline-danger" style={{ marginRight:'10px'}} >
										<i className="fa-solid fa-print"></i>  QZ Tray Desconectado
									</div>
								</div>
							)}

							<div className="nav-item">
								<Link to="/pos/kitchen" className="btn btn-white" style={{ marginRight:'10px'}} >
									<i className="fa-solid fa-list"></i>  Monitor de Cozinha
								</Link>
							</div>
							
						</div>
					</div>
					
					{!this.state.waiting ? (
						<div className="pos-counter-body">
							<div className="pos-counter-content">
								
								<PerfectScrollbar className="pos-counter-content-container">
									{this.state.filtroAplicao.length > 0 && (
										<div className="table-row">
											{this.state.filtroAplicao.map((item,i) =>
											
												<div key={"item_"+item.id} className={item.status !== "fechada" && item.status !== "cancelada" ? "table in-use" : "table available" }>
													<Link to="/pos/loja" data-id={item.id} data-mesa={item.mesa ? item.mesa.identificador : 0} data-comanda={item.id} className="table-container" onClick={(e) => this.handleComanda(e)}  style={{background:"#181C2A"}}>
														<div className="table-name" style={{position:"relative", alignItems: "start"}}>
															{item.mesa && item.mesa.b_comanda === 0 && (
																<>
																	<div className="name"  style={{color:"white"}}>Mesa</div>
																	<div className="no"  style={{color:"white",}}>{item.mesa.identificador}</div>

																	<span className="badge px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"  style={{border:"1px solid #FF8C21", color:"#FF8C21"}}>
																		{item.status === 'aberta' && (
																			<>
																				EM ATENDIMENTO
																			</>
																		)}
																		{item.status === 'cancelada' && (
																			<>
																				CANCELADO
																			</>
																		)}
																		{item.status === 'producao' && (
																			<>
																				EM PRODUÇÃO
																			</>
																		)}
																		{item.status === 'caminho' && (
																			<>
																				SAIU P/ENTREGA
																			</>
																		)}
																		{item.status === 'entregue' && (
																			<>
																				PEDIDO ENTREGUE
																			</>
																		)}
																	</span>

																	<div className="table-status" style={{top:'unset', bottom:"0", width:"100%", borderRadius:"0", right:"0", backgroundColor:"#FF8C21"}}></div>
																</>
															)}
															{item.mesa && item.mesa.b_comanda === 1 && (
																<>
																	<div className="name"  style={{color:"white"}}>Comanda</div>
																	<div className="no"  style={{color:"white",}}>{item.mesa.identificador}</div>

																	<span className="badge px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{border:"1px solid #B871FF", color:"#B871FF"}}>
																		{item.status === 'aberta' && (
																			<>
																				EM ATENDIMENTO
																			</>
																		)}
																		{item.status === 'cancelada' && (
																			<>
																				CANCELADO
																			</>
																		)}
																		{item.status === 'producao' && (
																			<>
																				EM PRODUÇÃO
																			</>
																		)}
																		{item.status === 'caminho' && (
																			<>
																				SAIU P/ENTREGA
																			</>
																		)}
																		{item.status === 'entregue' && (
																			<>
																				PEDIDO ENTREGUE
																			</>
																		)}
																	</span>

																	<div className="table-status" style={{top:'unset', bottom:"0", width:"100%", borderRadius:"0", right:"0", backgroundColor:"#B871FF"}}></div>
																</>
															)}
															{item.b_delivery === 1 && (
																<>
																	<div className="name"  style={{color:"white"}}>Delivery</div>
																	<div className="no"  style={{color:"white",}}>{item.id}</div>

																	<span className="badge px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{border:"1px solid #0078FF", color:"#0078FF"}}>
																		{item.status === 'analise' && (
																			<>
																				PENDENTE
																			</>
																		)}
																		{item.status === 'cancelada' && (
																			<>
																				CANCELADO
																			</>
																		)}
																		{item.status === 'producao' && (
																			<>
																				EM PRODUÇÃO
																			</>
																		)}
																		{item.status === 'caminho' && (
																			<>
																				SAIU P/ENTREGA
																			</>
																		)}
																		{item.status === 'entregue' && (
																			<>
																				PEDIDO ENTREGUE
																			</>
																		)}
																	</span>

																	<div className="table-status" style={{top:'unset', bottom:"0", width:"100%", borderRadius:"0", right:"0", backgroundColor:"#0078FF"}}></div>
																</>
															)}
															{item.b_takeaway === 1 && (
																<>
																	<div className="name"  style={{color:"white"}}>Comanda <i className="fa fa-bag-shopping"></i></div>
																	<div className="no"  style={{color:"white", fontSize:"17"}}>A{item.id}</div>

																	<span className="badge  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{border:"1px solid #B871FF", color:"#B871FF"}}>
																		{item.status === 'aberta' && (
																			<>
																				<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> OCUPADA
																			</>
																		)}
																		{item.status === 'cancelada' && (
																			<>
																				<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> CANCELADO
																			</>
																		)}
																		{item.status === 'producao' && (
																			<>
																				<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> EM PRODUÇÃO
																			</>
																		)}
																		{item.status === 'caminho' && (
																			<>
																				<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> SAIU PARA ENTREGA
																			</>
																		)}
																		{item.status === 'entregue' && (
																			<>
																				<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> ENTREGUE AO CLIENTE
																			</>
																		)}
																	</span>

																	<div className="table-status" style={{top:'unset', bottom:"0", width:"100%", borderRadius:"0", right:"0", backgroundColor:"#B871FF"}}></div>
																</>
															)}
															{this.hasImpressao(item)}
														</div>
														<div className="table-info-row">
															<div className="table-info-col">
																<div className={ this.changePedidos(item) }>
																	<span className="icon"  style={{color:"white"}}>
																		<i className="fa fa-hand-point-up"></i>
																	</span>
																	<span className="text"  style={{color:"white"}}> 
																		{ item.pedidos.length }
																	</span>
																</div>
															</div>
															<div className="table-info-col">
																<div className="table-info-container">
																	<span className="icon"  style={{color:"white"}}>
																		<i className="fa fa-dollar-sign"></i>
																	</span>
																	<span className="text"  style={{color:"white"}}>R$	
																		{ item.pedidos.length > 0 && ( item.pedidos.reduce((a,g) => a += parseInt(g.valor), 0 ).toFixed(2) )}</span>
																</div>
															</div>
														</div>
													</Link>
												</div>
											
											)}
										</div>
									)}

									<div className="table-row">
										<h3 style={{ color: "white", margin: "20px", marginTop: "60px" }}>Mesas e comandas disponíveis:</h3>
									</div>

									<div className="row" style={{ background: "#1f2225", padding:"20px" }}>
										<div className="col-md-6">
											<a href="#" className="btn btn-gray" data-filtro="mesas" onClick={(e) => this.dashFiltroMesas(e)} style={this.state.dashFiltroMesas === "mesas" ? { marginRight:"20px", padding: "10px 25px", cursor:"pointer", position: "relative", background:"#0043ff" } : { marginRight:"20px", padding: "10px 25px", cursor:"pointer", position: "relative", background:"#35383B" }}>
												Mesas
											</a>
											<a href="#" className="btn btn-gray" data-filtro="comandas" onClick={(e) => this.dashFiltroMesas(e)} style={this.state.dashFiltroMesas === "comandas" ? { marginRight:"20px", padding: "10px 25px", cursor:"pointer", position: "relative", background:"#0043ff" } : { marginRight:"20px", padding: "10px 25px", cursor:"pointer", position: "relative", background:"#35383B" }}>
												Comandas
											</a>

											{this.state.dashFiltroMesas === "comandas" && (
												<a className="btn btn-primary" style={{paddingTop: "10px"}} data-mesa="takeaway" data-comanda={null} onClick={(e) => this.handleComanda(e)}>
													Gerar comanda avulsa
												</a>
											)}
										</div>

										<div className="col-md-6">
											<div className="input-group">
												<button className="btn btn-dark" type="button" data-bs-toggle="dropdown" style={{border:"1px solid white"}}>
													<span className="d-none d-md-inline">Buscar</span>
												</button>
												
												<div className="flex-fill position-relative">
													<div className="input-group">
														<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
															<i className="fa fa-search opacity-5" style={{color:"white"}}></i>
														</div>
														<input type="text" className="form-control px-35px bg-dark" style={{color:"white"}} placeholder=""  onChange={(e) => this.busca(e)}/>
													</div>
												</div>
											</div>
										</div>
									</div>

									{this.state.mesasDisponiveis.length > 0 && (
										<div className="table-row">
											{this.state.mesasDisponiveis.map((item,i) =>
					
												<div key={"item_"+item.id} className="table available">
													<Link to="/pos/loja" data-id={item.id} data-mesa={item.identificador} data-comanda={null} className="table-container" onClick={(e) => this.handleComanda(e)}>
														<div className="table-name" style={{position:"relative", alignItems: "start", }}>
															
															{item.b_comanda === 0 && (
																<>
																	<div className="name"  style={{color:"white"}}>Mesa <i className="fa fa-utensils"></i></div>
																	<div className="no"  style={{color:"white"}}>{item.identificador}</div>
																	<div className="table-status" style={{top:'unset', bottom:"0", width:"100%", borderRadius:"0", right:"0"}}></div>
																</>
															)}
														
															{item.b_comanda === 1 && (
																<>
																	<div className="name"  style={{color:"white"}}>Comanda <i className="fa fa-bag-shopping"></i></div>
																	<div className="no"  style={{color:"white"}}>{item.identificador}</div>
																	<div className="table-status" style={{top:'unset', bottom:"0", width:"100%", borderRadius:"0", right:"0", backgroundColor:"#0043ff"}}></div>
																</>
															)}
														
														</div>
														<div className="table-info-row">
															<div className="table-info-col">
																<div className="table-info-container" style={{color:"white"}}>
																	<span className="icon">
																		<i className="fa fa-hand-point-up"></i>
																	</span>
																	<span className="text"> 0 </span>
																</div>
															</div>
															<div className="table-info-col">
																<div className="table-info-container"  style={{color:"white"}}>
																	<span className="icon">
																		<i className="fa fa-dollar-sign"></i>
																	</span>
																	<span className="text">R$ 0,00</span>
																</div>
															</div>
														</div>
													</Link>
												</div>
											
											)}
										</div>
									)}
								</PerfectScrollbar>
							</div>
							{(this.state.comanda || this.state.loadingComanda) && ( 
								<div className="pos-counter-sidebar" id="pos-counter-sidebar">
									{this.state.loadingComanda ? (
										<Lottie animationData={lottieLoaderComanda} loop={true} style={{width: "100%"}} />
									) : (
										<div style={{padding:'0', height:"100%"}}>
											<div className="pos-sidebar-header" style={{height:"10%"}}>
												{this.state.comanda.b_delivery === 1 && (
														<div className="title">Delivery {this.state.comanda.id}</div>
												)}

												{this.state.comanda.b_delivery === 0 && this.state.comanda.b_takeaway === 0 && this.state.comanda.mesa?.b_comanda === 0 && (
														<div className="title">Mesa {this.state.mesaSelecionada} <button className="btn btn-black" style={{ marginLeft:'10px', padding: '5px'}} onClick={(e) => this.toggleModalMesa(e)}>Trocar Mesa</button></div>
												)}

												{this.state.comanda.b_delivery === 0 && this.state.comanda.b_takeaway === 0 && this.state.comanda.mesa?.b_comanda === 1 && (
														<div className="title">Comanda {this.state.mesaSelecionada} </div>
												)}

												{this.state.comanda.b_takeaway === 1 && (
														<div className="title">Comanda A{this.state.comanda.id}</div>
												)}

												{devicesInativos.length > 0 && (
													<div className="btn btn-success" style={{ width: 'max-content', marginRight: "5px" }} onClick={(e) => this.handleValidaImpressao(e)}>
														<i className="fa fa-print"></i>
													</div>
												)}

												{this.state.comanda.b_delivery === 0 && (
													<button className="btn btn-success" style={{ width: 'max-content' }} onClick={(e) => this.toggleModalProdutos(e)}>Adicionar produto no pedido</button>
												)}
											</div>
											<div className="pos-sidebar-body" style={{height:"45%", paddingLeft:"5%", overflowX: 'hidden', backgroundColor: '#171717'}}>
												
												
												<div className="pos-table" data-id="pos-table-info" style={{height:"40%", padding: '0'}}>
													{this.state.comanda.pedidos.length > 0 ? (
														this.state.comanda.pedidos.map((item, idx) =>
															item.produtos.map((p, pidx) => 
																<div key={"produto"+p.id+"_"+idx} style={p.pagamento ? { opacity: '0.3', filter:' grayscale(1)'} : {}}>
																
																	<div className="row pos-table-row">
																		{this.state.comanda.b_delivery === 0 && (
																			<div className="" style={{display:"inline", width:'5%', paddingLeft:'5px'}}>
																				{p.pagamento ? 
																					<input type="checkbox" className="form-check-input"  disabled={true} checked={true} />
																				:
																					<input type="checkbox" className="form-check-input" id={p.token} onClick={() => this.hadleSelectProduto(p.token)} />
																				}
																				<label className="form-check-label" for={"product_"+p.id}></label>
																			</div>
																		)}
																		<div className="" style={{display:"inline", width:'40%', paddingLeft:'5px'}}>
																			<div className="pos-product-thumb">
																				{p.imagem ? (
																					<div className="img" style={{backgroundImage: 'url(https://grubupload.s3.amazonaws.com/'+p.imagem+')'}}></div>
																				) : (
																					<div className="img" style={{backgroundImage: 'url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)'}}></div>
																				)}
																				<div className="info">
																					<div className="title">{p.nome}</div>
																					<div className="title" style={{fontSize:'12px'}}>
																						{p.variacao && p.variacao.length > 0 && (
																							p.variacao[0].nome
																						)}
																					</div>
																					<div className="desc">R$ {p.valor} &nbsp; x{p.quantidade}</div>
																					
																				</div>
																			</div>
																			
																		</div>

																		<div className="" style={{display:"inline", width:'15%', margin:'0 5px'}}>
																			{devicesInativos.length > 0 && p.impresso ? (
																				<div className="badge" style={{color:"green", border:"1px solid green"}}>
																					<i className="fa fa-clock"></i> Impresso
																				</div>
																			) : <></>}
																			
																			{devicesInativos.length > 0 && !p.impresso ? (
																				<div className="badge" style={{color:"grey", border:"1px solid grey"}}>
																					<i className="fa fa-print"></i> Não Impresso
																				</div>
																			) : <></>}
																		</div>
																			
																		<div className="" style={{display:"inline", width:'15%', padding:'0 5px'}}>R${this.getItemPrice(p)}</div>
																		<div className="" style={{display:"inline", width:'20%', paddingLeft:'5px'}}>

																			{p.pagamento ?
																				<div className="form-check form-switch" style={{display:'inline-block'}}>	
																					<input className="form-check-input form-check-input-pos" type="checkbox" onClick={(e) => e.preventDefault()} id={"checkbox_"+p.id} checked={p.produtoEntregue} />
																				</div>
																			:
																				<div className="form-check form-switch" style={{display:'inline-block'}}>	
																					<input className="form-check-input form-check-input-pos" type="checkbox" onChange={(e) => this.handleSetStatus(e, p.pivot_id, p.produtoEntregue == true ? false : true)} id={"checkbox_"+p.id} checked={p.produtoEntregue} />
																				</div>
																			}

																			<span style={{marginLeft: "10px", color: "#e8ebef", cursor: "pointer"}} onClick={(e) => this.handlePlusInfo(e, idx+"_"+p.id+"_"+pidx) }>
																				Opções <i className="fa fa-caret-down"></i>
																				{this.state.plusInfo === idx+"_"+p.id+"_"+pidx && (
																					<div style={{position: "absolute", background: "#fff", width: "180px", right: "54px", zIndex: "2000", borderRadius:"10px"}}>

																						{devicesInativos.length > 0 ? (
																							<>
																								<button onClick={(e) => this.handleReImpressao(e, p.id, idx+"_"+p.id+"_"+pidx)} style={{border:"none", margin:"10px 5px 5px 5px", background:"none"}}>
																									<i className="fa fa-print" style={{marginRight:"5px"}}></i> Re-imprimir item
																								</button>
																								<hr />
																								<button onClick={(e) => this.handlerRemoveItem(e,item.id, p.id, p.pivot_id, idx+"_"+p.id+"_"+pidx)} style={{border:"none", margin:"0px 5px 10px 5px", background:"none", color:"red"}}>
																									<i className="fa fa-trash" style={{marginRight:"5px"}}></i> Cancelar item
																								</button>
																							</>
																						) : (
																							<button onClick={(e) => this.handlerRemoveItem(e,item.id, p.id, p.pivot_id, idx+"_"+p.id+"_"+pidx)} style={{border:"none", margin:"10px 5px 10px 5px", background:"none", color:"red"}}>
																								<i className="fa fa-trash" style={{marginRight:"5px"}}></i> Cancelar item
																							</button>
																						)}
																					</div>
																				)}
																			</span>

																		</div>
																	</div>
																	
																</div>
															)
														)
													)
													:
													(
														<>
															<Lottie animationData={lottiejson} loop={true} style={{width: "350px", margin:"auto", height: "250px"}} />
															<h2 style={{textAlign:"center"}}>Nenhum pedido por aqui!</h2>
															<p style={{textAlign:"center"}}>Inclua os produtos no botão superior acima.</p>
														</>
													)}
												</div>
												
											</div>
											<div className="pos-sidebar-footer" style={{height:"45%", padding:'0'}}>

												{this.state.comanda.b_delivery ? (
													<div className="total" style={{background:'#171717', padding: '2px 10px', fontSize: '12px' }}>
														<div className="text">Status do pedido:</div>
														<div className="price" style={{width:'60%'}} data-id="price-subtotal">
															<Link to="/pos/counter-checkout" data-status={this.state.comanda.status} onClick={(e) => this.toggleStatus(e)}>	
																{this.state.comanda.status === 'analise' && (
																	<p className='btn btn-outline-warning' style={{margin:"10px 0"}}>PENDENTE</p>
																)}
																{this.state.comanda.status === 'cancelada' && (
																	<p className='btn btn-outline-danger' style={{margin:"10px 0"}}>CANCELADO</p>
																)}
																{this.state.comanda.status === 'producao' && (
																	<p className='btn btn-outline-primary' style={{margin:"10px 0"}}>EM PRODUÇÃO</p>
																)}
																{this.state.comanda.status === 'caminho' && (
																	<p className='btn btn-outline-primary' style={{margin:"10px 0"}}>SAIU PARA ENTREGA</p>
																)}
																{this.state.comanda.status === 'entregue' && (
																	<p className='btn btn-outline-success' style={{margin:"10px 0"}}>ENTREGUE AO CLIENTE</p>
																)}
															</Link>
														</div>
													</div>
												):(<></>)}
												
												<div className='selecionado' style={this.state.selectedProduto.length > 0 ? {background:"#0043ff", padding: '10px'} : {}} >
													{this.state.selectedProduto.length > 0 && (
														<div className="subtotal">
															<div className="text">Subtotal itens selecionados</div>
															<div className="price" style={{width:'60%'}} data-id="price-subtotal">R$ {this.valorComanda()}</div>
														</div>
													)}

													{this.state.comanda.b_delivery === 1 ?(
														<div className="subtotal"  style={{padding: '10px'}}>
															<div className="text">Subtotal</div>
															<div className="price" style={{width:'60%'}} data-id="price-subtotal">R$ {this.valorComanda()}</div>
														</div>
													):<></>}

													{this.state.comanda.b_delivery === 1 ?(
														<div className="taxes" style={{padding: '10px'}}>
															<div className="text">
																<div className="form-check form-switch" style={{width:'70%', float:"right"}}>	
																	<label className="form-check-label" htmlFor="checkbox1">Taxa de entrega: </label>
																</div>
															</div>
															<div className="price" style={{width:'60%'}} data-id="price-subtotal">R$ {parseFloat(taxaEntrega)}</div>
														</div>
													) : <></>}

													{this.state.comanda && this.state.comanda.voucher && (
														<div className="subtotal"  style={{padding: '5px'}}>
															<div className="text">Descontos e promoções</div>
															<div className="price" style={{width:'60%'}} data-id="price-subtotal">{this.calculaDesconto().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
														</div>
													)}

													{this.state.comanda.b_delivery === 0 && this.state.login.loja.b_taxa_servico ? (
														<div className="subtotal"  style={{padding: '5px'}}>
															<div className="text">Subtotal</div>
															<div className="price" style={{width:'60%'}} data-id="price-subtotal">R$ {this.valorComanda()}</div>
														</div>
													): <></>}
													
													{this.state.comanda.b_delivery === 0 && this.state.login.loja.b_taxa_servico ? (
														<div className="taxes" style={{padding: '5px'}}>
															<div className="text">
																<div className="form-check form-switch" style={{width:'70%', float:"right"}}>	
																	<input className="form-check-input form-check-input-pos" type="checkbox" onChange={() => this.handleSetTaxa()} id="checkbox1" checked={this.state.handleSetTaxa} style={{marginTop: "5px"}} />
																	<label className="form-check-label" htmlFor="checkbox1">Taxa de serviço ({taxa}%) </label>
																</div>
															</div>
															<div className="price" style={{width:'60%'}} data-id="price-subtotal">R$ {this.valorTaxa()}</div>
														</div>
													) : <></>}
													
												</div>
												

												{!this.state.comanda.b_delivery && (
													<div className="total" style={{background:'#171717', padding: '2px 10px', fontSize: '12px' }}>
														<div className="text">Número de pessoas: <input type='text' className="form-control" style={{width: '40px', backgroundColor: '#1f2225', display: 'inline-block', height: '20px', color: 'white'}} value={this.state.numeroPessoas} onChange={(e) => this.setState({numeroPessoas: e.target.value})} /></div>
														<div className="price" style={{fontSize: '12px', width:'60%'}} data-id="price-subtotal">R$ {(this.valorFaltante() / parseInt(this.state.numeroPessoas)).toFixed(2)}</div>
													</div>
												)}

												<div className="total" style={{background:'#171717', padding: '2px 10px'}}>
													{this.state.comanda.b_delivery == 1 && (
														<div className="text">Total do pedido:</div>
													)}
													{this.state.comanda.b_delivery == 0 && (
														<div className="text">Total da mesa:</div>
													)}
													{this.state.comanda.b_takeaway == 1 && (
														<div className="text">Total da Comanda:</div>
													)}
													<div className="price" style={{width:'60%'}} data-id="price-subtotal">{this.valorTotalComanda().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
												</div>

												<div style={{position:"absolute", bottom:"0", width:"100%"}}>
													{this.state.comanda.b_delivery === 0 && (
														<div className="btn-row" style={{padding:'1px 0', margin:0}}>
															<Link to="#" className={this.state.tipoPagamento == 'pix'? "btn width-250 btn-black" : "btn btn-default width-250"} data-tipe="pix" style={{width: '25%'}}  onClick={(e) => this.changeTipoPagamento(e)}>
																<div className="icon"><i className="fa-brands fa-pix fa-fw fa-lg"></i></div>
																PIX
															</Link>
															<Link to="#" className={this.state.tipoPagamento == 'dinheiro'? "btn width-250 btn-black" : "btn btn-default width-250"} data-tipe="dinheiro" style={{width: '25%'}}  onClick={(e) => this.changeTipoPagamento(e)}>
																<div className="icon"><i className="fa fa-money-bill fa-fw fa-lg"></i></div>
																DINHEIRO
															</Link>
															<Link to="#" className={this.state.tipoPagamento == 'credito'? "btn width-250 btn-black" : "btn btn-default width-250"} data-tipe="credito" style={{width: '25%'}}  onClick={(e) => this.changeTipoPagamento(e)}>
																<div className="icon"><i className="fab fa-cc-visa fa-fw fa-lg"></i></div>
																CRÉDITO
															</Link>
															<Link to="#" className={this.state.tipoPagamento == 'debito'? "btn width-250 btn-black" : "btn btn-default width-250"} data-tipe="debito" style={{width: '25%'}}  onClick={(e) => this.changeTipoPagamento(e)}>
																<div className="icon"><i className="fa fa-money-check fa-fw fa-lg"></i></div>
																DÉBITO / VA
															</Link>
														</div>
													)}

													{this.state.comanda.b_delivery === 0 ?
														<div className="btn-row" style={{padding:'1px 0', margin:0}}>
															{this.produtoAberto() ? 
																<Link to="/pos/counter-checkout" className="btn btn-success"  onClick={(e) => this.toggleModal(e)}>
																	<div className="icon"><i className="fa fa-cash-register fa-fw fa-lg"></i></div>
																	FECHAR CONTA
																</Link>
															:
																<Link to="/pos/counter-checkout" className="btn btn-primary"  onClick={(e) => this.handleFechaComanda(e)}>
																	<div className="icon"><i className="fa-solid fa-check fa-fw fa-lg"></i></div>
																	LIBERAR OU FECHAR
																</Link>
															}
														</div>
													:
														<div className="btn-row" style={{padding:'1px 0', margin:0}}>
															<Link to="/pos/counter-checkout" className="btn btn-primary" style={{width:"100%"}}  data-status="producao" onClick={(e) => this.toggleStatus(e)}>
																<div className="icon"><i className="fa-solid fa-motorcycle fa-fw fa-lg"></i></div>
																MOVER STATUS DE DELIVERY
															</Link>
														</div>
													}
												</div>
											</div>								
										</div>
									)}
								</div>
							)}
						</div>
					) : (
						<div className="card border-0" style={{padding:"15px"}}>
							<Lottie animationData={lottiewating} loop={true} style={{width: "880px", margin:"auto"}} />
							<h1 style={{textAlign:"center"}}>Carregando os pedidos do seu ponto de venda.</h1>
							<p  style={{textAlign:"center"}}>Aguarde uns intantes...</p>
						</div>
					)}
				</div>

				<Modal isOpen={this.state.modalPagamento}>
					
					<ModalBody>
						<div className="row">
							<div className="col-md-6"><h5>Confirmação de pagamento:</h5></div>
							
							<div className="col-md-6" style={{textAlign:"right"}}>
								<button className="btn btn-white" onClick={(e) => this.imprimeComprovante(e)}>Imprimir comprovante <i className="fa fa-print"></i></button>
							</div>
						</div>
						<hr/>
						{this.state.selectedProduto.length || this.state.comanda ? (
							<>
								<p style={{textAlign:'center', fontSize:'22px'}}>
									Prosiga a realizar o pagamento na sua maquina {this.state.selectedProduto.length ? "parcial" : "total"} em <b>{this.state.tipoPagamento}</b>.
									de um valor de <b>{this.valorComandaTaxa().toString()}, após concluido confirme a cobrança no botão abaixo.</b>
								</p>
								<p style={{textAlign:'center'}}>
									<Lottie animationData={lottiepagamento} loop={true} style={{width: "50%", margin:"auto"}} />
								</p>
							</>
						) : null}
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={(e) => this.toggleModal(e)}>Cancelar</button>
						<button className="btn btn-success" onClick={(e) => this.handlePagaProduto(e)}>Compra concluida, salvar pagamento.</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.modalPagamentoDelivery}>
					<ModalHeader>Pedido entregue ao cliente:</ModalHeader>
					<ModalBody>
						{this.state.selectedProduto.length || this.state.comanda && (
							<>
								<p style={{textAlign:'center', fontSize:'22px'}}>
								Seu cliente já esta com o produto nas mãos, feche o pedido e vamos pelo próximo.
								</p>
								<p style={{textAlign:'center'}}>
									<Lottie animationData={lottieDelivery} loop={true} style={{width: "50%", margin:"auto"}} />
								</p>
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={(e) => this.toggleModalPagamentoDelivery(e)}>Cancelar</button>
						<button className="btn btn-success" onClick={(e) => this.handlePagaProdutoDelivery(e)}>Confirmar entrega e fechar pedido.</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.modalTroca}>
					<ModalHeader>Troca de Mesa</ModalHeader>
					<ModalBody>
						<Lottie animationData={lottieTrocaMesa} loop={true} style={{width: "300px", margin:"auto"}} />
						
						<div style={{fontSize:'20px'}}>Trocar Mesa: <b>{this.state.mesaSelecionada}</b> </div>
						<div>
							<DropDown 
								nome="id_mesa"
								setFomrData={this.handleNewMesa}
								options={this.dropdownMesas()}
								dadosIniciais={false}
								label="Selecione uma Mesa"
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={(e) => this.toggleModalMesa(e)}>Cancelar</button>
						<button className="btn btn-success" onClick={(e) => this.toggleModalMesa(e)}>Aplicar</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.modalAbreMesa}>
					<ModalHeader>Abrir</ModalHeader>
					<ModalBody>
						<h3 style={{textAlign:'center'}}>Você deseja abrir a mesa / comanda: {this.state.mesaSelecionada}</h3>
						<p style={{textAlign:'center'}}>Após a abertura, você poderá incluir produtos nela.</p>
						<Lottie animationData={lottieAbrirMesa} loop={true} style={{width: "300px", margin:"auto"}} />
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={(e) => this.toggleModalAbreMesa(e)}>Cancelar</button>
						<button className="btn btn-success" onClick={(e) => this.handleAbrirMesa(e)}>Abrir {this.state.mesaSelecionada}</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.toggleModalProdutos} size='xl' modalClassName="modal-pos-item">
					<ModalHeader>Incluir produtos: <b>{this.state.mesaSelecionada}</b></ModalHeader>
					<ModalBody>
						<div style={{display: 'flex', justifyContent: 'space-between', height:'500px'}}>
							<div style={{width:'60%',height: '100%'}}>
								<input placeholder='Busca de produtos' type="text" className="form-control" onChange={(e) => this.handleFilterProdutos(e)} />
								<div className="table-responsive" style={{marginTop:'15px', height: '89%', overflowY:'scroll'}}>
									<table className="table table-striped mb-0 align-middle">
										<tbody>
											{this.state.filterProdutos.map(item => {
												const urlImagem = item.imagem && item.imagem.imagem ? "https://grubupload.s3.us-east-1.amazonaws.com/"+item.imagem.imagem : "https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg"
												return (
													<tr key={"produto_"+item.id}>
														<td>
															<div className="img" style={{background: 'url('+urlImagem+')', backgroundPosition:'center', backgroundSize:'cover', backgroundRepeat:'no-repeat', borderRadius:'10%', height:'50px', width:'50px'}}>
															</div>
														</td>
														<td><strong style={{textTransform:"uppercase"}}>{item.nome}</strong></td>
														<td><strong style={{textTransform:"uppercase"}}>{item.categoria.nome}</strong></td>
														<td>R$ {item.valor}</td>
														<td><button className="btn btn-primary" onClick={(e) => this.handleSelectedFromLista(e, item)}>VER ITEM</button></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
							{this.state.selectedFromLista ? (
								<div style={{width: '40%', margin:' 0px 10px', padding:'20px'}}>
									<div style={{background: '#F4F4F4', borderRadius: '10px', overflowY:"scroll", height:"94%"}}>
										<div style={{height:"20%", width:"100%", maxWidth:"100%", backgroundImage: "url("+this.getImage(this.state.selectedFromLista)+")", backgroundSize: "cover", backgroundPosition: "center"}}></div>	
											
										<div className="pos-product">
											<div className="pos-product-info" style={{flex:"unset", width:"100%", maxWidth:"100%"}}>
												<div className="title">{this.state.selectedFromLista.nome.toUpperCase()}</div>
												<div className="desc">{this.state.selectedFromLista.detalhe}</div>
												<div className="price">R$ {this.state.selectedFromLista.valor}</div>
											
												<div className="input-group qty" style={{width: '100px', margin: '10px 0'}}>
													<div className="input-group-append">
														<Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('remove')} ><i className="fa fa-minus"></i></Link>
													</div>
													<input style={{margin: 0, border: 0, padding: 0, textAlign: 'center', lineHeight: '1', height: '26px', width: '25px', fontWeight: '600', background:'transparent'}} type="text" className="form-control" value={this.state.pedidoQuantidade} />
													<div className="input-group-prepend">
														<Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('add')}><i className="fa fa-plus"></i></Link>
													</div>
												</div>

												{this.state.selectedFromLista.b_personalizado && this.state.selectedFromLista.variacao.length > 0 ? (
													<div className="option-row">
														<div className="option-list">
															{this.state.selectedFromLista.variacao.map( (item) => {
																return (
																	<div key={"variacao_"+item.nome} className="option" checked onClick={() => { this.setState({variacao: item}) }}>
																		<label className="option-label" for="size3" style={this.state.variacao === item ? {borderColor:'#0043ff'} : {}}>
																			<span className="option-text">{item.nome}</span>
																			<span className="option-price">R$ {item.valor}</span>
																		</label>
																	</div>
																)
															} )}
															
														</div>
													</div>
												) : (
													<></>
												)}

												{this.state.opcionaisProduto.length > 0 && (
													<div className="option-row">
														<h4>Deseja retirar algum opcional?</h4>

														<div className="option-list">
															{this.state.opcionaisProduto.map( (item) => {
																return (
																	<div key={"variacao_"+item.nome} className="option" checked onClick={() => { this.handleOpcionais(item) }}>
																		<label className="option-label" for="size3" style={this.state.opcionaisSelecionados.find((i) => {return i.nome == item.nome}) ?  {borderColor:'#0043ff'} : {}}>
																			<span className="option-text"> {item.nome} </span>
																		</label>
																	</div>
																)
															} )}
															
														</div>
														
													</div>
												)}

												{this.state.adicionaisProduto.length > 0 && (
													<div className="option-row">
														<h4>Deseja incluir algum adicional?</h4>

														<div className="option-list">
															{this.state.adicionaisProduto.map( (item) => {
																return (
																	<div key={"variacao_"+item.nome} className="option" checked onClick={() => { this.handleOpcionais(item, false) }}>
																		<label className="option-label" for="size3" style={this.state.adicionaisSelecionados.find((i) => {return i.nome == item.nome}) ? {borderColor:'#0043ff'} : {} }>
																			<span className="option-text"> {item.nome} </span>
																			{item.valor && (
																				<span className="option-price">R$ {item.valor}</span>
																			)}
																		</label>
																	</div>
																)
															} )}
															
														</div>
													</div>
												)}

												<div className="form-floating mb-15px">
													<textarea className='form-control' name="complemento" onChange={(e) => this.handleComentario(e.target.value)} />
													<Label nome="complemento" label="Comentario"/>
												</div>
												
											</div>
										</div>
									</div>

									<button style={{marginTop: '10px', width:'100%'}} className="btn btn-success" onClick={(e) => this.handleAddPedido(e, this.state.selectedFromLista)}>Adicionar produtos no pedido</button>
								</div>
							)
							:
							(
								<div style={{width: '40%', margin:' 0px 10px',background: '#F4F4F4', borderRadius: '10px', padding:'20px', overflowY:"scroll"}}>
									<Lottie animationData={lottieProcuraProduto} loop={true} style={{width: "100%", margin:"auto"}} />
									<h3 style={{textAlign:'center'}}>Utilize a função de busca de produtos e adicione o item que você deseja incluir.</h3>
								</div>
							)}
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" style={{ position: "absolute", left: "10px"}} onClick={(e) => this.toggleModalProdutos(e)}>Fechar</button>
						<button className="btn btn-success" style={{float:"right"}}onClick={() => this.savePedido()}>Enviar pedido</button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.modalCancelaComanda}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>O Pedido será cancelado.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalCancelaComanda: false })}>Fechar</button>
							<button className="btn btn-danger" onClick={(e) => this.handleCancelaComanda(e)}>Cancelar pedido</button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalCancela}>
					<ModalBody>
						<div>
							<div className="alert mb-5">
								<h3>Cancelamento de produtos.</h3>

								{ this.state.dadosCancelmento && (
									<p> Será cancelado x{this.state.dadosCancelmento.produto.quantidade} unidades de <b>{this.state.dadosCancelmento.produto.nome}</b> da {this.state.mesaSelecionada} </p>
								)}

								<p> Informe abaixo o <b>motivo do cancelmanto:</b> </p>

								<div className="row">

									<div className='col-md-6'>
										<div className="form-check">
											<input className="form-check-input" name="motivoCancelamento" type="radio" id="motivoCancelamento1"onChange={() => this.setState({motivoCancelamento:1})} checked={this.state.motivoCancelamento === 1} />
											<label className="form-check-label" for="motivoCancelamento1">Produto lançado errado</label>
										</div>

										<div className="form-check">
											<input className="form-check-input" name="motivoCancelamento" type="radio" id="motivoCancelamento2" onChange={() => this.setState({motivoCancelamento:2})} checked={this.state.motivoCancelamento === 2} />
											<label className="form-check-label" for="motivoCancelamento2">Produto em duplicidade</label>
										</div>

										<div className="form-check">
											<input className="form-check-input" name="motivoCancelamento" type="radio" id="motivoCancelamento3" onChange={() => this.setState({motivoCancelamento:3})} checked={this.state.motivoCancelamento === 3} />
											<label className="form-check-label" for="motivoCancelamento3">Outro</label>
										</div>
									</div>
									<div className='col-md-6'>
										<div className="form-check">
											<input className="form-check-input" name="motivoCancelamento" type="radio" id="motivoCancelamento4" onChange={() => this.setState({motivoCancelamento:4})} checked={this.state.motivoCancelamento === 4} />
											<label className="form-check-label" for="motivoCancelamento4">Cliente desistiu do produto</label>
										</div>

										<div className="form-check">
											<input className="form-check-input" name="motivoCancelamento" type="radio" id="motivoCancelamento5" onChange={() => this.setState({motivoCancelamento:5})} checked={this.state.motivoCancelamento === 5} />
											<label className="form-check-label" for="motivoCancelamento5">Cliente recusou o produto</label>
										</div>
									</div>

								</div>
							</div>
							
							<input type="text" className="form-control mb-5" placeholder="Senha PDV" onChange={(e) => this.setState({senhaPDV: e.target.value}) } />
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalCancela: false })}>Fechar</button>
							<button className="btn btn-danger" onClick={(e) => this.executaCancelamento()}>Confirmar cancelamento</button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalConfigImpressora}>
					<ModalBody>
						<div className='text-center'>
							<FormImpressao showSetor={false} />
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalConfigImpressora: false })}>Fechar</button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalConfirmacaoReimpressaoGeral}>
					<ModalBody>
						<div className='text-center'>
						<Lottie animationData={lottieAlertaReimpressao} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: Todos os produtos já foram impresos anteriormente.</h3>
								<p>Tem certeza que deseja imprimir todos novamente?.</p>
							</div>

							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalConfirmacaoReimpressaoGeral: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.handleImpressora(e, true)}>Sim, re-imprimir pedidos</button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalStatusDelivery} backdrop={true}>
					<ModalBody>
						<div className="row">
							<div className='col-md-6'>
								<button className="btn btn-white"  data-status="producao" onClick={(e) => this.imprimeComprovante(e)}>
										IMPRIMIR COMPROVANTE <i className="fa-solid fa-print fa-fw "></i> 
								</button>
							</div>
							<div className='col-md-6' style={{textAlign: "right"}}>
								<button type="button" className="btn-close" onClick={(e) => this.toggleStatus(e)}></button>
							</div>
						</div>

						{this.state.comanda && (
							<div className='text-center'>
								{this.state.comanda.status === 'analise' && (
									<Lottie animationData={lottieAnimationDelivery} loop={true} style={{width: "290px", margin:"auto"}} />
								)}

								{this.state.comanda.status === 'producao' && (
									<Lottie animationData={lottiePreparo} loop={true} style={{width: "290px", margin:"auto"}} />
								)}

								{this.state.comanda.status === 'caminho' && this.state.comanda.entrega && !this.state.comanda.entrega.id_motoboy && (
									<Lottie animationData={lottieDeliveryEnviando} loop={true} style={{width: "290px", margin:"auto"}} />
								)}

								{this.state.comanda.status === 'caminho' && this.state.comanda.entrega && this.state.comanda.entrega.id_motoboy && (
									<Lottie animationData={lottiePedidoEnviadoCozinhaApp} loop={true} style={{width: "290px", margin:"auto"}} />
								)}

								{this.state.comanda.status === 'fechada' && (
									<Lottie animationData={lottieDeliveryEntregue} loop={true} style={{width: "290px", margin:"auto"}} />
								)}
								
							</div>
						)}

						{this.state.comanda && (
							<div className="nav-wizards-container">
								<nav className="nav nav-wizards-3 mb-2">
									
									<div className="nav-item col">
										<a className="nav-link completed" href="#">
											<div className="nav-dot"></div>
											<div className="nav-no" style={{textAlign: "center"}}>Pedido</div>
										</a>
									</div>

									<div className="nav-item col">
										<a className={this.state.comanda.status === 'analise' ? "nav-link" : "nav-link completed"} href="#">
											<div className="nav-dot"></div>
											<div className="nav-no" style={{textAlign: "center"}}>Preparo</div>
										</a>
									</div>

									{this.state.comanda.entrega && (
										<div className="nav-item col">
											<a className={(this.state.comanda.status === 'analise' || this.state.comanda.status === 'producao')? "nav-link" : "nav-link completed"} href="#">
												<div className="nav-dot"></div>
												<div className="nav-no" style={{textAlign: "center"}}>Entregador</div>
											</a>
										</div>
									)}

									{this.state.comanda.entrega && (
										<div className="nav-item col">
											<a className={(this.state.comanda.entrega.id_motoboy) && (this.state.comanda.status === 'analise' || this.state.comanda.status === 'producao' || this.state.comanda.status === 'caminho') ? "nav-link completed" : "nav-link"} href="#">
												<div className="nav-dot"></div>
												<div className="nav-no" style={{textAlign: "center"}}>Delivery</div>
											</a>
										</div>
									)}

									<div className="nav-item col">
										<a className={this.state.comanda.status === 'fechada' ? "nav-link completed" : "nav-link"} href="#">
											<div className="nav-dot"></div>
											<div className="nav-no" style={{textAlign: "center"}}>Entregue </div>
										</a>
									</div>

								</nav>
							</div>
						)}

						<div className="mb-5" style={{backgroundColor:"#ebebeb", padding:"10px", borderRadius:"5px"}}>
							{this.state.comanda && this.state.comanda.entrega && (
								<div className='row mb-5'>
									<div className='col-md-6'>
										<strong>{this.state.comanda.entrega.nome}</strong><br/>
										{this.state.comanda.entrega.telefone}
										<hr />
										{this.state.comanda.entrega && this.state.comanda.entrega.motoboy && (
											<>
												<strong>Entregador</strong><br/>
												{this.state.comanda.entrega.motoboy.nome}
											</>
										)}
									</div>

									<div className='col-md-6'>
										<Link to={{pathname:"whatsapp://send?text=Segue informaçoes do pedido: "+this.state.comanda.id+" esta no status: "+this.state.comanda.status+"&phone=+55"+this.state.comanda.entrega.telefone.replace("(","").replace(")","").replace("-","").replace(" ","")}} className="btn btn-outline-success"  target="_blank"> 
										<i class="fa-brands fa-whatsapp"></i> Falar com o cliente
										</Link>
									</div>
								</div>
							)}

							{this.state.comanda && this.state.comanda.entrega && (
								<div className='row'>
									<div className='col-md-12'>
										<address>
											<strong>Endereço:</strong> R. {this.state.comanda.entrega.rua}, {this.state.comanda.entrega.numero} - {this.state.comanda.entrega.bairro}, {this.state.comanda.entrega.cidade} - {this.state.comanda.entrega.cep}
										</address>
									</div>
								</div>
							)}
						</div>

						<div className='row'>
							<div className='col-md-12'>
								{this.state.comanda && this.state.comanda.status === 'analise' && (
									<button className="btn btn-success"  data-status="producao" onClick={(e) => this.changeStatus(e)}>
										<i className="fa-solid fa-circle-check fa-fw"></i> ACEITAR PEDIDO
									</button>
								)}
								{this.state.comanda && this.state.comanda.status === 'producao' && (
									<button className="btn btn-primary"  data-status="caminho" onClick={(e) => this.changeStatus(e)}>
										<i className="fa-solid fa-truck-fast fa-fw"></i> SOLICITAR ENTREGADOR
									</button>
								)}
								{this.state.comanda && this.state.comanda.status === 'caminho' && (
									<button className="btn btn-success"  data-status="entregue" onClick={(e) => this.toggleModalPagamentoDelivery(e)}>
										<i className="fa-solid fa-circle-check fa-fw"></i> ENTREGUE
									</button>
								)}

								{this.state.comanda && (this.state.comanda.status === 'analise' || this.state.comanda.status === 'producao') && (
									<button className="btn btn-outline-danger" style={{marginLeft:'10px'}} onClick={(e) => this.handleModalCancelaComanda(e)}>
										<i className="fa fa-trash"></i> CANCELAR PEDIDO
									</button>
								)}
							</div>
						</div>
					</ModalBody>
				</Modal>
			
			</div>
		)
	}
}

export default Loja