import axios from 'axios'
import Helper from './../config/helper.js';

export const doLogin = async (data) => {
  const result = await axios.post('https://xerxes.grub.digital/v1/gerencial/login', data)
  const d = new Date()
  result.expire = d.setDate(d.getDate()+1)
  
  localStorage.setItem('user', JSON.stringify(result))

  return result
}

export const getEndereco = async (endereco) => {
  const result = await axios.get('https://xerxes.grub.digital/enderecocompleto/'+endereco)
  return result.data
}

export const validaBrand = async (nome) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/validanome/'+nome)

  return result.data
}

export const trocaloja = async (data) => {
  const result = await axios.post('https://xerxes.grub.digital/v1/gerencial/trocaloja', data, 
  { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+Helper.getToken() }})
  
  const d = new Date()
  result.expire = d.setDate(d.getDate()+1)
  
  localStorage.setItem('user', JSON.stringify(result))

  return result
}

export const createLogin = async (data) => {
  const domain = Helper.getDominio()
  const result = await axios.post(domain+'/v1/gerencial/register', data)
  const d = new Date()
  result.expire = d.setDate(d.getDate()+1)

  localStorage.setItem('user', JSON.stringify(result))

  return result
}

export const getMenu = () => {
    const domain = Helper.getDominio()

    return axios.get(domain+'/v1/gerencial/Menu', {headers:{'Authorization': 'Bearer '+Helper.getToken()}}).then((result) => {
        const r = result.data
        let Menu = []

        r.forEach( e => {
            let children = []

            e.menu.forEach(i => {
                children.push({ path: i.model, icon: i.icon ? 'fa '+i.icon : null,  title: i.label  })
            })

            Menu.push({ path: e.model, title: e.label, children: children })
        })

        return Menu
    } )
}

export const me = async () => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/me', {headers:{'Authorization': 'Bearer '+Helper.getToken()}})
  return result
      
}

export const getList = async (model, params) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/'+model, {params:params, headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result.data
}

export const getBusca = async (model, params) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/search/'+model, {params:params, headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result.data
}

export const getPedidosFinalizado = async() => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/Pedido/finalizado')

  return result.data
}

export const getItemsToKichen = async() => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/pedido/kitchen', {headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result.data
}

export const getDash = async(di = null, df = null) => {
  const domain = Helper.getDominio()
  let result = null

  if (di && df) {
    result = await axios.get(domain+'/v1/gerencial/dash?di='+di+'&df='+df, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})
  } else {
    result = await axios.get(domain+'/v1/gerencial/dash', {headers:{'Authorization': 'Bearer '+Helper.getToken()}})
  }
    

  return result.data
}

export const gestaoEntregadorLoja = async (data) => {
  const domain = Helper.getDominio()
  await axios.post(domain+'/v1/gerencial/gestaoEntregadorLoja', data, { headers: { 'Authorization': 'Bearer '+Helper.getToken() }})
}

export const deleteObj = async (model) => {
  const domain = Helper.getDominio()
  await axios.delete(domain+'/v1/gerencial/'+model,  { headers: { 'Authorization': 'Bearer '+Helper.getToken() }})
}

export const removeProduto = async (pedido, pivot, motivo=null) => {
  const domain = Helper.getDominio()
  await axios.delete(domain+'/v1/gerencial/pedido/produto', { headers: { 'Authorization': 'Bearer '+Helper.getToken() }, data:{id:pedido, pivot:pivot, motivo:motivo}})
}

export const removeOpcional = async (opcional, produto, tipo = "opcional") => {
  const domain = Helper.getDominio()
  await axios.delete(domain+'/v1/gerencial/opcional/produto', { headers: { 'Authorization': 'Bearer '+Helper.getToken() }, data:{'id_opcional':opcional, 'id_produto':produto, tipo: tipo}})
}

export const saveObj = async (data, model) => {
  const domain = Helper.getDominio()

  const result = await axios.post(
    domain+'/v1/gerencial/'+model,
    data,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+Helper.getToken() }}
  )

  return result
}

export const changeOrder = async (ref, direcao = "cima") => {
  const domain = Helper.getDominio()
 
  const result = await axios.post(
    domain+'/v1/gerencial/order/'+ref+'?direcao='+direcao, {},
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+Helper.getToken() }}
  )

  return result
}

export const uploadFile = async (data) => {
  const domain = Helper.getDominio()

  await axios.post(
    domain+'/v1/gerencial/Imagem',
    data,
    { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+Helper.getToken()}}
  )
}

export const confirmaimpressao = async (data) => {
  const domain = Helper.getDominio()

  await axios.post(
    domain+'/v1/gerencial/confirmaimpressao',
    data,
    { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+Helper.getToken()}}
  )
}

export const getStruct = async (model) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/struct/'+model, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result
}

export const getStructList = async (model) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/struct-list/'+model, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result.data
}

export const getWizard = async (model) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/wizard/'+model, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result
}

export const getStructNest = async (model) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/struct-nest/'+model, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result
}

export const getItem = async (model, id, filter = null) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/'+model+'/'+id, {headers:{'Authorization': 'Bearer '+Helper.getToken()}, params:{filtro:filter}})

  return result
}

export const getItemFromRelation = async (relation) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/'+relation, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})

  return result
}

export const getCep = async (cep) => {
  const result = await axios.get('https://xerxes.grub.digital/endereco/'+cep)
  return result.data
}

export const getResultados = async (date) => {
  const domain = Helper.getDominio()
  const result = await axios.get(domain+'/v1/gerencial/metas-resultados/'+date, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})
  return result.data
}

export const getMetas = async (mes = null) => {
  const domain = Helper.getDominio()
  const url = mes ? domain+'/v1/gerencial/meta/'+mes : domain+'/v1/gerencial/meta/'
  const result = await axios.get(url, {headers:{'Authorization': 'Bearer '+Helper.getToken()}})
  return result.data
}

export const saveMetas = async (data) => {
  const domain = Helper.getDominio()

  await axios.post(
    domain+'/v1/gerencial/meta',
    data,
    { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+Helper.getToken()}}
  )
}

export const removeProdutoDestaque = async (produto, prioridade) => {
  const domain = Helper.getDominio()
  await axios.delete(domain+'/v1/gerencial/destaque/produto', { headers: { 'Authorization': 'Bearer '+Helper.getToken() }, data:{produto:produto, prioridade:prioridade}})
}

export const getPDVData = () => {
  return new WebSocket('wss://lucifer.grub.digital?token='+Helper.getToken())
}
