import React from 'react'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'

export default class ValoresProduto extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasInitial: false,
            tipoPreco: 0,
            promocional: 0,
            atual: null,
            lengthOpicionais: 1,
            personalizado:[],
            diasFuncionamento: 0
		}

        this.handleTipoPreco    = this.handleTipoPreco.bind(this)
        this.handlePromocional  = this.handlePromocional.bind(this)
        this.showOpcionais      = this.showOpcionais.bind(this)
        this.listaOpcionais     = this.listaOpcionais.bind(this)
        this.handlerMaisOpcionais   = this.handlerMaisOpcionais.bind(this)
        this.handlerMenosOpcionais  = this.handlerMenosOpcionais.bind(this)
        this.handlerValorPersonalizado = this.handlerValorPersonalizado.bind(this)
        this.handleOnChangeDiasFuncionamento = this.handleOnChangeDiasFuncionamento.bind(this)
    }

    componentDidUpdate () {
        if (!this.state.hasInitial && this.props.atual.id) {
            const atual = this.props.atual
            
            this.setState({atual:atual, promocional:atual.b_promocao, tipoPreco:atual.b_personalizado})
            if (atual.dias_promocao) this.setState({diasFuncionamento: atual.dias_promocao})

            if (atual.variacao && atual.variacao.length > 0) {
                this.setState({lengthOpicionais:atual.variacao.length})
                this.setState({personalizado: atual.variacao})
            }

            this.setState({hasInitial:true})
        }
      }

    handleTipoPreco () {
        const tipo = this.state.tipoPreco ? 0 : 1
       
        this.setState({tipoPreco:tipo})
        this.props.setFomrData("b_personalizado", tipo)
    }

    handlePromocional () {
        const tipo = this.state.promocional ? 0 : 1
        const dias = this.props.atual.dias_promocao ? this.props.atual.dias_promocao : 0
        this.props.setFomrData("b_promocao", tipo)
        this.setState({promocional:tipo, diasFuncionamento:dias})
    }

    handleOnChangeDiasFuncionamento(e){
		const diasFuncionamento = parseInt(this.state.diasFuncionamento)
        let dias = null

		if (e.target.checked) {
			dias = diasFuncionamento + parseInt(e.target.value)
		} else {
			dias = diasFuncionamento - parseInt(e.target.value)
		}

        this.setState({ diasFuncionamento: dias })
        this.props.setFomrData("dias_promocao", dias)
	}

    handlerMaisOpcionais(e) {
        e.preventDefault()

        if (this.state.lengthOpicionais < 3) {
            const quantidade = this.state.lengthOpicionais + 1

            this.setState({lengthOpicionais: quantidade})
        }
    }

    handlerMenosOpcionais(e) {
        e.preventDefault()

        if (this.state.lengthOpicionais > 0) {
            const quantidade = this.state.lengthOpicionais - 1
            this.setState({lengthOpicionais: quantidade})
            this.props.unsetRelationData(quantidade)
        }
    }

    handlerValorPersonalizado(obj){
        const item = obj.name.split("_")
        const value = obj.value

        const personalizados = this.state.personalizado

        if (item[0] === 'nome') {
            if (personalizados.length > item[1]) personalizados[item[1]].nome = value
            else personalizados.push({nome: value})
        } else {
            if (personalizados.length > item[1]) personalizados[item[1]].valor = value.replace(",",".")
            else personalizados.push({valor: value})
        }

        this.props.setFomrRelation("Variacao",personalizados)
    }

    listaOpcionais(i){
        return (
            <div className='row mb-30px'>
                <div className='col-md-6'>
                    <div className="form-floating">    
                        <InputText
                            nome={"nome_"+i}
                            setFomrData={this.handlerValorPersonalizado}
                            returnObject={true}
                            dadosIniciais={this.state.personalizado.length > 0 && i <= this.state.personalizado.length-1 ? this.state.personalizado[i].nome : null}
                        />
                        <Label nome="nome" label="Texto do opcional"/>
                    </div>
                </div>
            
                <div className='col-md-6'>
                    <div className="form-floating">
                        <InputText
                            nome={"valor_"+i}
                            setFomrData={this.handlerValorPersonalizado}
                            decimal={true}
                            returnObject={true}
                            dadosIniciais={this.state.personalizado.length > 0 && i <= this.state.personalizado.length-1 ? this.state.personalizado[i].valor : null}
                        />
                        <Label nome="valor" label="Valor"/>
                    </div>
                </div>
            </div>
        )
    }

    showOpcionais () {
        const lista = []
        const quant = this.state.lengthOpicionais ? this.state.lengthOpicionais : 1

        for(let i=0; i<quant; i++){
            lista.push(this.listaOpcionais(i))
        }

        return (
            <>
                {lista}

                {quant < 6 && (
                    <span style={{cursor:"pointer"}} className='col-md-6' onClick={this.handlerMaisOpcionais}>
                        Incluir novo valor opcional 
                    </span>
                )}

                {quant > 1 && (
                    <span style={{cursor:"pointer", float: 'right'}} className='col-md-6' onClick={this.handlerMenosOpcionais}>
                        Remover valor opcional 
                    </span>
                )}
                
                
            </>
        )
    }

    render() {
        const atual = this.props.atual

        const dias = this.state.diasFuncionamento
        const binario = '0x'+(dias >>> 0).toString(2).padStart(7, 0)

        const data = {
            dom:!!('0x'+(1 >>> 0).toString(2).padStart(7, 0) & binario),
            seg:!!('0x'+(2 >>> 0).toString(2).padStart(7, 0) & binario),
            ter:!!('0x'+(4 >>> 0).toString(2).padStart(7, 0) & binario),
            qua:!!('0x'+(8 >>> 0).toString(2).padStart(7, 0) & binario),
            qui:!!('0x'+(16 >>> 0).toString(2).padStart(7, 0) & binario),
            sex:!!('0x'+(32 >>> 0).toString(2).padStart(7, 0) & binario),
            sab:!!('0x'+(64 >>> 0).toString(2).padStart(7, 0) & binario)
		}

        return (
            <>
                <div className='row mb-15px'>
                    <Label nome="valores" label="Valores"/>
                </div>

                <div className='row mb-15px'>
                    <div className='col-md-4'>
                        <div className="form-floating">    
                            <InputText
                                nome="custo_producao"
                                setFomrData={this.props.setFomrData}
                                dadosIniciais={atual? atual.custo_producao : ""}
                                decimal={true}
                            />
                            <Label nome="custo_producao" label="Custo do produto"/>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="form-floating">    
                            <InputText
                                nome="valor"
                                setFomrData={this.props.setFomrData}
                                dadosIniciais={atual? atual.valor : ""}
                                decimal={true}
                            />
                            <Label nome="valor" label="Valor venda QR code"/>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="form-floating">    
                            <InputText
                                nome="valor_delivery"
                                setFomrData={this.props.setFomrData}
                                dadosIniciais={atual? atual.valor_delivery : ""}
                                decimal={true}
                            />
                            <Label nome="valor_delivery" label="Valor venda Delivery"/>
                        </div>
                    </div>
                </div>

                <div className='row mb-15px' style={{padding:'0 10px'}}>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={this.state.tipoPreco === 1} onChange={this.handleTipoPreco} name="b_promocional" id="flexRadioDefault1" />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Valor personalizado
                        </label>
                    </div>
                </div>


                { this.state.tipoPreco === 0 && (
                    <div className='row mb-15px'  style={{padding:'0 10px'}}>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={this.state.promocional} onChange={this.handlePromocional} id="flexSwitchCheckDisabled" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDisabled">Este produto tem valores promocionais</label>
                        </div>
                    </div>
                )}

                { this.state.promocional === 1 && this.state.tipoPreco === 0 && (
                    <div className='row mb-15px'>
                        <div className='col-md-6'>
                            <div className="form-floating">    
                                <InputText
                                    nome="valor"
                                    setFomrData={this.props.setFomrData}
                                    dadosIniciais={atual? atual.valor : ""}
                                    decimal={true}
                                />
                                <Label nome="valor" label="Valor"/>
                            </div>
                        </div>
                       
                        <div className='col-md-6'>
                            <div className="form-floating">
                                <InputText
                                    nome="valor_promocional"
                                    setFomrData={this.props.setFomrData}
                                    dadosIniciais={atual? atual.valor_promocional : ""}
                                    decimal={true}
                                />
                                <Label nome="valor_promocional" label="Valor Promocional"/>
                            </div>
                        </div>

                        <div className='row' style={{margin:'30px 0'}}>
                            <p>Promoção em dias específicos:</p>
                            <div style={{padding:'0 10px 10px 10px'}}>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="dom" checked={data.dom} value="1" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox1" />
                                    <label className="form-check-label" htmlFor="checkbox1">DOM</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="seg" checked={data.seg} value="2" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox2" />
                                    <label className="form-check-label" htmlFor="checkbox2">SEG</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="ter" checked={data.ter} value="4" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox3" />
                                    <label className="form-check-label" htmlFor="checkbox3">TER</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="qua" checked={data.qua} value="8" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox4" />
                                    <label className="form-check-label" htmlFor="checkbox4">QUA</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="qui" checked={data.qui} value="16" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox5" />
                                    <label className="form-check-label" htmlFor="checkbox5">QUI</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="sex" checked={data.sex} value="32" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox6" />
                                    <label className="form-check-label" htmlFor="checkbox6">SEX</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="sab" value="64" checked={data.sab} className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox7" />
                                    <label className="form-check-label" htmlFor="checkbox7">SAB</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.tipoPreco === 1 && this.showOpcionais()}
            </>
        )
    }
}