
import React from 'react'

import { saveObj, getEndereco } from '../../store/api.js'
import { AppSettings } from '../../config/app-settings.js'

import Helper       from '../../config/helper'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

export default class FormSenha extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            endereco: null,
            user: null,
            data:{
              items:{ 'id': this.props.id }
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async setModelData(valor) {
        const endereco = valor.value.terms
        let data = this.state.data

		if (endereco.length === 5) {
            data.items["estado"] = endereco[4].value
            data.items["cidade"] = endereco[3].value
            data.items["bairro"] = endereco[2].value
            data.items["numero"] = endereco[1].value
            data.items["rua"] 	 = endereco[0].value
        } else {
            data.items["estado"] = endereco[3].value
            data.items["cidade"] = endereco[3].value
            data.items["bairro"] = endereco[2].value
            data.items["numero"] = endereco[0].value.split(",")[1]
            data.items["rua"] 	 = endereco[0].value.split(",")[0]
        }
       
        const gps = await getEndereco(data.items["numero"]+"+"+data.items["rua"].replaceAll(" ","+")+"+"+data.items["bairro"].replaceAll(" ","+")+", "+data.items["cidade"].replaceAll(" ","+")+", "+data.items["estado"])
    
        data.items["lat"] 	 = gps.lat
        data.items["lng"] 	 = gps.lng

        this.setState({ data })
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, "atualizaendereco")
          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    async componentDidMount() {
        const user = this.context.stateUserdata
        this.setState({user: user})

        if (user && user.endereco){
            this.setState({endereco: {logradouro: user.endereco.rua, bairro:user.endereco.bairro}})
            this.setState({data:{
                items:{ 'id': this.props.id, cep: user.endereco.cep, numero: user.endereco.numero }
            }})
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} style={{margin: "0 30px"}}>
                <h4 className="card-title">Delivery</h4>
                <p className="card-text">Para começar a vender no delivery, você precisa preencher o CEP e o endereço completo da sua loja na seção abaixo. Essas informações são essenciais para calcular as taxas de entrega e para que os clientes saibam exatamente onde sua loja está localizada.</p>

                <div className='row'>

                    <div className="col-md-6">
                        <div className="form-floating mb-15px" style={{position: "relative", height: "40px"}}>
                            <div style={{position:"absolute", zIndex:"1000", width: "100%"}}>
                                <GooglePlacesAutocomplete apiKey="AIzaSyDT4DEK1lYFBmdqBZZhZeU6Wo1bcE2D4k4" 
                                    GooglePlacesDetailsQuery={{ fields: "geometry" }}
                                    apiOptions={{ language: 'br', region: 'br' }}
                                    selectProps={{
                                        onChange: this.setModelData,
                                        placeholder:"Digite um endereço com numero"
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}